// coding:utf-8
// Copyright 2021 Scout Exchange, LLC. All Rights

.placement-fee-success-chart {
  border: 1px solid $placement-fee-success-border;
  padding: spacer(2);
  position: relative;

  // hidden element intended only as a host for a Pendo guide
  .pendo-info {
    background-color: transparent;
    color: transparent;
    position: absolute;
    height: spacer(5);
    width: spacer(5);
    top: spacer(1);
    right: spacer(1);
  }

  .linear-gauge-wrap {
    margin: 0 4%;
  }

  @include media-breakpoint-only(md) {
    .linear-gauge-scale-value {
      font-size: $font-size-sm;
    }
  }
}
