// coding:utf-8
// Copyright 2013-2020 Scout Exchange, LLC. All Rights

.pager-wrap {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.pager {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li > a,
  li > span {
    // override Bootstrap's styles
    border-width: 0;
    padding: inherit;
  }

  .badgebutton {
    display: block;
    width: 100%;
    height: 100%;
    margin-right: 2px;
  }

  .pager-item {
    margin-left: 10px;

    a {
      display: block;
      height: 25px;
      width: 25px;
    }
  }
}

.pager-text {
  font-size: 12px;
  order: -1;
  padding-top: 3px;
  white-space: nowrap;
}
