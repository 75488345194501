// coding:utf-8
// Copyright 2013-2017 Scout Exchange, LLC. All Rights

// Candidate Count styles

.cand-count-display {
  margin-top: 10px;
  margin-bottom: 10px;

  .item-highlight & {
    // change border color from "-lighter" to "-light" which makes
    // the border one level darker (despite what the variable name suggests)
    border-color: $primary-variant-light;
  }
}

.cand-count-display .disabled {
  color: $typical-gray;
  cursor: default;
}

.cand-header {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cand-stats-wrap {
  > div {
    display: inline-block;
  }
}

.cand-stats {
  display: inline-block;
  margin-right: 11px;
  text-align: center;
}

.cand-category,
.cand-count {
  display: block;
}

.cand-category {
  font-size: 12px;

  // margin-top to space between categories if they wrap
  margin-top: 3px;
  white-space: nowrap;
}

.cand-count {
  font-size: 16px;
  font-weight: 600;
  margin-top: -2px;
}

@include media-breakpoint-down(sm) {
  .cand-stats {
    min-width: 0;
  }
}

@include media-breakpoint-up(lg) {
  .cand-count-display {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .cand-stats {
    margin-right: 5px;
    min-width: 6em;
  }
}
