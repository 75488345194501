// coding:utf-8
// Copyright 2013-2018 Scout Exchange, LLC. All Rights

// styles for ui/hiring/components/back_link.jsx

.back-link {
  cursor: pointer;
}

.back-link-wrap {
  margin-top: 11px;
  margin-right: 1em;

  // use padding for extra spacing
  // to keep margin at simple "em"-based size
  padding-right: 4px;
  position: relative;
  z-index: 2;
}
