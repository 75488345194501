// coding:utf-8
// Copyright 2021 Scout Exchange, LLC. All Rights

.recruiter-fee {
  // Selector necessary to override Bootstrap
  .form-check .semi-bold {
    font-weight: $font-weight-semibold;
  }
}

.recruiter-fee-toggle-wrap {
  display: flex;
}

.recruiter-fee-toggle {
  flex: 1;
  max-width: spacer(31);
}

.recruiter-fee-value {
  align-items: center;
  display: flex;
}

.recruiter-fee-value-fixed {
  .form-control {
    max-width: spacer(25);
    margin-right: spacer(2);
  }
}

.recruiter-fee-value-percent {
  .form-control {
    width: auto;
    margin-right: spacer(2);
  }
}

.executive-search-fixed-fee {
  padding-left: 30px;
}
