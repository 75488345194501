// coding:utf-8
// Copyright 2013-2022 Scout Exchange, LLC. All Rights

// styles for the "Selected Recruiter" list/accordion in the sidebar of the
// Job Listing page for an open job

.added-recruiters-group {
  margin-bottom: spacer(1);

  .checkbox-item {
    font-weight: $font-weight-normal;
    margin-right: 5px;
    padding-left: 30px;
  }
}

.added-recruiters-group-toggle {
  font-weight: $font-weight-semibold;

  .material-symbols-outlined {
    margin-right: spacer(1);
    position: relative;
    top: 3px;
  }
}

.added-recruiter {
  // reduce line-height and add margin-bottom to help item separation if text
  // wraps
  line-height: 1.4;
  margin-bottom: spacer(0.5);
  margin-left: spacer(8);
}
