// Badge styles
// variations on Bootstrap's ".badge" concept

.badge {
  // used on CandidateDetailsPage.tsx to indicate "Account Inactive"
  &.inactive {
    background-color: $badge-inactive-bg;
    color: $badge-inactive-color;
    font-weight: 400;
  }
}
