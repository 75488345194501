// coding:utf-8
// Copyright 2013-2022 Scout Exchange, LLC. All Rights

$matched-text: #ff0;

// Styles specific to the Select Partners page

.select-recruiters-wrap {
  // reduce top margin on Button Row to move it closer to content
  .btn-row {
    margin-top: 0;

    &.with-border {
      border-color: $primary-variant-light;
    }
  }

  // reduce top padding on Footer to move it closer to buttons
  .app-footer {
    padding-top: spacer(3);
  }

  .page-header,
  .container-fluid {
    // fill full width to offset content centering caused by side margins being
    // set to "auto"
    width: 100%;
  }

  .page-info {
    padding: 0 $main-section-info-padding;
    font-style: italic;
    color: $brand-warning;
  }

  .scroll-y-wrap {
    // overrules "height" value and allows panel to fill available vertical
    // space
    flex: 1;
  }

  @include media-breakpoint-up(sm) {
    .l-side {
      border-left: 1px solid $primary-variant-light;
    }

    .l-main {
      border-right: 1px solid $primary-variant-light;

      // Tried Grid but IE11 doesn't support automatically placing items, so I
      // went with Flexbox
      display: flex;
      flex-direction: column;
    }

    .container-fluid {
      // Using Flex here because "container-fluid" renders ":before" and
      // ":after" pseudo-elements which count and are styled as grid items
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

.select-recruiters-filter-details {
  font-style: italic;
  margin-top: spacer(1);
}

.select-recruiters-list-header {
  align-items: flex-end;
  border-bottom: 1px solid $primary-variant-light;
  display: flex;
  line-height: 1.4;
  list-style-type: none;
  padding: px-to-rem(2) 0;
  margin-bottom: px-to-rem(4);

  // styles below are related
  // to making the header sticky
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 2;
}

.select-recruiters-provider-col {
  font-weight: $font-weight-bold;
  padding-right: spacer(2);
  margin-right: auto;
}

.select-recruiters-rec-col {
  padding-right: spacer(2);
}

.select-recruiters-rec-col,
.select-recruiters-rating-col {
  font-weight: $font-weight-bold;
  text-align: center;
  width: $select-recruiters-sm-col-width;
}

.select-recruiters-scroll-content {
  padding: 0 0 spacer(1);
  position: absolute;
  width: 100%;
}

.select-recruiters-text-match {
  background: $matched-text;
  margin: 0;
  padding: 0;
}
