// coding:utf-8
// Copyright 2013-2018 Scout Exchange, LLC. All Rights

// Styles for the candidate cards

.candidate-card {
  border: 1px solid $primary-variant-light;
  margin-bottom: 1em;

  &:last-child {
    margin: 0;
  }
}

.candidate-card-header,
.candidate-card-details {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.candidate-card-header {
  background-color: $primary-variant-lighter;
  border-bottom: 1px solid $primary-variant-light;

  h4 {
    display: inline-block;
    line-height: 1.3;
    margin: 0;

    a {
      @extend .underline;
    }
  }

  .main-content {
    min-width: 50%;
    padding: 0.75em 1em 0.2em;
  }

  .side-content {
    min-width: 50%;
    padding: 0.2em 1em 0.75em;

    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  @include media-breakpoint-up(md) {
    align-items: center;

    .main-content,
    .side-content {
      padding: 0.75em 1em;
    }
  }
}

.candidate-card-details {
  @include media-breakpoint-up(md) {
    .side-content {
      border-left: 1px solid $primary-variant-light;
    }
  }

  .main-content,
  .side-content {
    > div {
      font-size: 12px;
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .main-content {
    padding: 0.75em 1em;
  }
}

.no-candidates-for-review {
  text-align: center;
  padding-top: 2em;
  color: $primary-variant-dark;
}

.candidates-for-review-spinner {
  @extend .no-candidates-for-review;
}
