.message-thread {
  margin-bottom: 1em;
}

.message-content {
  @include clearfix;

  border: 1px solid $primary-variant-light;
  padding: 1em;

  &.private {
    border-color: $scout-primary;
  }
}

.message-private-header {
  background-color: $scout-primary;
  color: $white;
  padding: 9px 12px;

  .blurb {
    font-size: 12px;
    margin-left: 5px;
  }
}

.message-header {
  font-weight: $font-weight-bold;
}

.message-bubble,
.message-reply,
.message-ask-follow-up {
  border-radius: 4px;
  padding: 9px 12px;
}

.message-bubble {
  position: relative;

  // every <p> should have margin-bottom
  // however, if there's only a single <p>, remove the bottom margin
  > div > p {
    margin-bottom: 1em;

    &:first-child {
      margin-bottom: 0;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;

    // border-color is set on the specific instances
    border-width: 5px;
    border-style: solid;
  }

  &::before {
    bottom: -10px;
  }

  &::after {
    bottom: -8px;
  }
}

.message-timestamp {
  .badge {
    margin-left: px-to-rem(8);
  }
}

.message-sides {
  width: 80%;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.message-left {
  float: left;

  .message-bubble {
    background-color: $scout-action;
    border: 1px solid $scout-action;
    border-bottom-left-radius: 0;
    color: $white;

    &::before {
      border-color: $scout-action transparent transparent $scout-action;
      left: -1px;
    }

    &::after {
      border: 0;
      left: 0;
    }
  }

  .message-timestamp,
  .message-company {
    padding-left: 2px;
  }
}

.message-right {
  float: right;

  .message-bubble {
    background-color: $primary-variant-lighter;
    border: 1px solid $scout-primary;
    border-bottom-right-radius: 0;

    &::before {
      border-color: $scout-primary $scout-primary transparent transparent;
      right: -1px;
    }

    &::after {
      border-color: $primary-variant-lighter $primary-variant-lighter transparent transparent;
      right: 0;
    }
  }

  .message-timestamp,
  .message-company {
    text-align: right;
    padding-right: 2px;
  }
}

.message-reply,
.message-ask-follow-up {
  border: 1px solid $scout-primary;
  display: block;
}

.message-ask-follow-up {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
