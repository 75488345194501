// coding:utf-8
// Copyright 2013-2017 Scout Exchange, LLC. All Rights

// styles for standalone loaders and animations

@keyframes movement {
  50% {
    background-color: $scout-primary;
    transform: scale(1.5);
  }
}

.inline-dot-loader {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.loader-dot {
  height: $loader-sphere-size;
  width: $loader-sphere-size;
  position: relative;
  margin: 0 1px;

  &::after {
    @include create-content($top: calc(25%), $left: calc(25%), $width: 50%, $height: 50%);

    border-radius: 50%;
    animation-name: movement;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  // animation delay for each dot
  &:nth-child(1) {
    &::after {
      animation-delay: 0;
    }
  }

  &:nth-child(2) {
    &::after {
      animation-delay: 0.2s;
    }
  }

  &:nth-child(3) {
    &::after {
      animation-delay: 0.4s;
    }
  }
}
