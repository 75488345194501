// coding:utf-8
// Copyright 2013-2018 Scout Exchange, LLC. All Rights
@use "sass:color";

// Table styles

.table-default {
  margin-bottom: 25px;
  width: 100%;

  td {
    padding-top: 5px;
  }
}

// add this to the parent element of a table to
// create a table with 4 equal columns
.table-wrap-col-25 {
  @extend .row;

  td,
  th {
    display: inline-block;
    padding-right: 1em;
    padding-left: 1em;
    vertical-align: top;
    width: 25%;
  }
}

.table-no-border {
  tbody {
    tr {
      th,
      td {
        border-top: 0;
      }
    }
  }
}

// Override the Bootstrap table class to bypass using !important
.table.table-striped {
  .highlight-row {
    background: color.scale($extended-neutral-3, $alpha: -50%);
  }
}

