// coding:utf-8
// Copyright 2013-2024 Scout Exchange, LLC. All Rights

// =========
// 1. variable overrides
// - - - - -
// override Bootstrap variables that we need in TPX
// --------

// Relative path to the Glyphicon font files in scout-ui
// Must come before scout-ui imports, as it overrides the 'icon-font-path'
// variable
$icon-font-path: "~scout-ui/assets/scout-bootstrap-sass/assets/fonts/bootstrap/";


// =========
// 2. scout-ui base styles - import the core Scout stylesheet from 'scout-ui',
// which includes Bootstrap
// --------
@import "~scout-ui/src/scss/core";

// Load the 'scout-ui' styles for the MultiLocation component
@import "~scout-ui/src/components/job/MultiLocation/multi-location";


// =========
// 3. TPX variables, mixins, utilities
// --------
@import "scout-variables";
@import "scout-mixins";
@import "scout-utilities";
@import "smb/onboard-variables";


// =========
// 4. scout-ui components - import specific styles for scout-ui components
// --------
@import "~scout-ui/src/components/typography/typography";
@import "~scout-ui/src/components/layout/footer/footer";
@import "~scout-ui/src/components/button/button";

// 'dropdown' must be imported after 'button'
@import "~scout-ui/src/components/input/dropdown";

// 'navigation' must be imported after 'dropdown'
@import "~scout-ui/src/components/layout/navigation/navigation";
@import "~scout-ui/src/components/input/typeahead";
@import "~scout-ui/src/components/input/location-with-fallback";
@import "~scout-ui/src/components/logo/logo";
@import "~scout-ui/src/components/layout/stack/control-row";
@import "~scout-ui/src/components/input/radio";
@import "~scout-ui/src/components/input/input";
@import "~scout-ui/src/components/tabs/tabs";
@import "~scout-ui/src/components/charts/linear-gauge-chart";
@import "~scout-ui/src/components/util/icon";
@import "~scout-ui/src/components/loader/loader";
@import "~scout-ui/src/components/util/popover";
@import "~scout-ui/src/components/panel/alert";
@import "~scout-ui/src/components/panel/notice";
@import "~scout-ui/src/components/input/datepicker";
@import "~scout-ui/src/components/layout/pagination/pagination";
@import "~scout-ui/src/components/layout/modal/modal";
@import "~scout-ui/src/components/util/badge";
@import "~scout-ui/src/components/input/switch";

// =========
// 5. TPX components
// --------
@import "scouticon";

// Layout
@import "layout/onboard";
@import "layout/structure";
@import "layout/sidebar";
@import "layout/typography";

// Individual component styles
@import "components/buttons";
@import "components/button_row";
@import "components/back_link";
@import "components/page_title";
@import "components/pager";
@import "components/dropdown";
@import "components/tooltip";
@import "components/icon_guide";
@import "components/rich_text_editor";
@import "components/candidate_count";
@import "components/loader";
@import "components/modals";
@import "components/badges";
@import "components/message-thread";
@import "components/tables";
@import "components/candidate";
@import "components/candidate-for-review";
@import "components/checkbox";
@import "components/scout-ui/navigation";
@import "components/selector";
@import "components/sample-candidate-card";
@import "components/placement-fee-success";
@import "components/recruiter-fee";
@import "components/select-recruiters";
@import "components/added-recruiters";
@import "components/skip-to-content";
@import "components/partner-selection";

// =========
// 6. SMB/Onboard components
// --------
@import "smb/skill-nav";

// ============================================================ //
// global Bootstrap overrides //
// ============================================================ //

dt {
  font-weight: $font-weight-normal;
}

.btn {
  &:active,
  &.active {
    // remove box-shadow style from button "active" state
    box-shadow: none;
  }
}

.form-group {
  // increase bottom margin (BS default is '15px')
  margin-bottom: 25px;
}

// replaces ".relative" in the "FormInput" component and creates unique
// classname we can use for specific styling
.input-group-with-error {
  position: relative;
}

// used for grouping multiple fields, like how scout-ui's FieldGroup component
// works
// main use case was displaying Address 1, Address 2, City/State/Zip as a group
// reduces space between each field
// changes labels to be italic
.field-group {
  margin-bottom: 15px;

  label {
    font-weight: $font-weight-normal;
    font-style: italic;
  }

  .form-group {
    margin-bottom: 10px;
  }
}

.has-error {
  .help-block {
    // shrink default text size for error messaging text
    font-size: 12px;
  }

  .input-group-addon {
    // keep addon text visible on error, default is $brand-danger on
    // $brand-danger
    color: $white;
  }

  .share-recipients {
    color: $scout-primary;
  }
}

// for use when you need error validation styles but don't need the text to show
// added for: The zip code in Placement form has error text that relates to
//   both city and zip. The text was added on a separate level and, thus, can be
//   hidden under zip.
.has-error-hide-text {
  .help-block {
    display: none;
  }
}

// used to remove bottom margin from form-group
.form-group-no-margin {
  .form-group {
    margin-bottom: 0;
  }
}

// set font size on input fields to 16 on xs screens
// to prevent "zoom in" effect on mobile devices
.form-control {
  @include media-breakpoint-down(xs) {
    font-size: $font-size-mobile-forms;
    height: auto;
  }
}

// override Bootstrap's default so that the "x" appears in search text fields
input[type="search"]::-webkit-search-cancel-button {
  appearance: auto;
}

// ============================================================ //
// Base styles //
// ============================================================ //

img {
  max-width: 100%;
}

ul,
ol {
  padding-left: 25px;
}

textarea {
  // prevent horizontal resizing
  resize: vertical;
}

h3,
.h3 {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 10px;
}

h6,
.h6 {
  line-height: $line-height-base;
}

// used to override Bootstrap
legend {
  border: 0;
  border-bottom: 1px solid $legend-border-color;
  color: $legend-color;
  display: block;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: inherit;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
}

// Layout styles // --------------------------------------------------

.frameless-header {
  margin: px-to-rem(40) 0 px-to-rem(50);

  .aquent-scout-logo {
    width: 100%;
  }
}

.intro-box {
  margin-bottom: 20px;

  // if .h4 is either within .intro-box or combined with .intro-box
  .h4,
  &.h4 {
    line-height: 1.3;
  }
}

.ellipsis-block {
  @include ellipsis;

  display: block;
}

.header-description {
  color: $gray;
  margin-bottom: 20px;
}

.notes-block {
  label {
    font-weight: 400;
  }
}

.ndash {
  position: absolute;
  width: 10%;
  left: 50%;
  margin-left: -5%;
  text-align: center;
  top: 6px;
}

// App styles // --------------------------------------------------

.errorpage-sub-text {
  color: $primary-variant-dark;
}

.errorpage-alt-actions {
  margin: 50px 0 30px;
}

.no-results {
  font-size: 18px;
  text-align: center;
  padding: 20px 0;
  color: $no-results;
  line-height: 1.6;
}


// sorting --------------------------
.sort-dropdown {
  font-size: $font-size-sm;

  a {
    cursor: pointer;
  }
}

// ============================================================ //
// Layout //
// ============================================================ //

// positions alert to top of window --------------------------
.fixed-alert {
  display: none;
  position: fixed;
  width: 100%;

  // high value in order to override pendo icons, which have z-index of 19000
  z-index: 20000;
}

.multi-field-row {
  display: inline-block;

  label {
    font-weight: 400;
    font-size: 11px;
    margin-bottom: 2px;
  }
}

// ============================================================ //
// Grid //
// ============================================================ //

// adjust left and right padding on a "col-" element
// default "col-" padding is 1em
.col-pad-xs {
  padding-left: 0.25em;
  padding-right: 0.25em;
}

// ============================================================ //
// Modules //
// ============================================================ //

// styles for custom file upload UI --------------------------
.upload-input {
  font-size: 12px;
  padding: 5px 0;
}

.uploaded-file-name {
  @extend .m-l-sm;
  @include ellipsis;

  color: $primary-variant-darkest;
}

// to scroll Terms of Use (Terms of Service)
.scrollable-terms {
  // TODO this needs to be done with a outer container or something...
  height: 450px;
  overflow-y: scroll;
  padding: 10px;
  border-top: 1px solid $primary-variant-dark;
  border-bottom: 1px solid $primary-variant-dark;
  margin-bottom: 20px;
}

// show validation icon immediately next to element --------------------------
// initially created for file upload errors
.validation-icon-right {
  position: relative;
  display: inline-block;
  min-height: 34px;

  .has-error & {
    margin-right: 34px;
  }
}

// alert --------------------------

.alert {
  margin-bottom: spacer(2);
}

// FIXME: ".modal-alerts .alert" temporary styles to make alert paddings
// consistent on modals and pages until we can adjust code to
// use scout-ui's alert styles (smaller padding on alerts in modals)

// To use scout-ui's styles, we need to wrap the alert's "children"
// with <div class"alert-content"> to make use of the flexbox layout

.modal-alerts .alert {
  padding-top: $alert-padding-x, $alert-padding-y;
  padding-bottom: $alert-padding-x, $alert-padding-y;
}

// end FIXME

.resend-feedback-wrap {
  margin: 1px 0 0 auto;
  padding-left: 10px;

  .resend-button-wrap {
    align-items: flex-start;
    display: flex;
  }

  .form-control-feedback {
    font-size: px-to-rem(21);
    padding-left: 10px;
    position: relative;
    width: auto;
  }
}

#placement-details-form .has-feedback div.placement-override-container ~ .form-control-feedback {
  top: 46px;
}

// stack-item styles --------------------------
// generic grouping style

.stack-item {
  padding: 12px 0;
  position: relative;
}

.stack-item-border-bottom {
  border-bottom: 1px solid $primary-variant-light;
}

.stack-callout {
  border: solid $gray-base 1px;
  border-color: $scout-primary;
  font-size: 0.8em;
  padding: 0.5em;
  display: inline-block;
  margin-bottom: 0.75em;
}

.stack-item-border {
  border: 1px solid $primary-variant-light;
}

.stack-item-border-top {
  border-top: 1px solid $primary-variant-light;
}

.stack-headline {
  // define size, weight, and line-height
  // to reset styles applied to <h2>
  font-size: $font-size-base;
  font-weight: $font-weight-semibold;
  line-height: $line-height-base;
  margin-bottom: 5px;
  margin-top: 0;
  display: inline-block;
}

.stack-headline-summary {
  display: inline-block;
  font-weight: 600;
}

.stack-headline-candidate {
  margin-bottom: 5px;
  display: flex;

  .svg-btn {
    margin-left: 7px;
  }
}

.stack-headline-candidate-name {
  font-size: $font-size-lg;
  font-weight: $font-weight-semibold;
  line-height: $line-height-base;
}

.stack-detail {
  + .stack-detail {
    @extend .m-t-xs;
  }
}

.stack-status {
  @include media-breakpoint-only(md) {
    float: right;
  }
}

.stack-info-primary {
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    margin-bottom: 10px;
  }
}

.stack-detail-sm {
  font-size: $font-size-sm;
}

// stylelint-disable no-descending-specificity

.inline-radio-text {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;

  .form-check {
    margin: 0 7px 0 0;
  }

  .form-group {
    flex: 1;
  }
}

.listing-search-job-form {
  margin-bottom: 10px;

  .form-group {
    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    @include media-breakpoint-up(xs) {
      margin-bottom: 0;
      margin-right: 10px;
      width: $copy-job-field-width-xs-min;
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 5px;
      margin-right: 0;
      width: $copy-job-field-width-sm-min;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      margin-right: 10px;
      width: $copy-job-field-width-md-min;
    }
  }
}

.client-filter-wrap {
  .form-group {
    margin-bottom: spacer(2.5);
  }
}

// Overriding the scout-ui css for performance fee split.
.form-group .form-group {
  .control-label.pfs {
    font-style: normal;
  }

  .control-label.pfs-expiring-date {
    font-weight: $font-weight-bold;
  }
}

.form-check {
  + .inline-radio-text {
    margin-top: -5px;
  }

  .label-text-disabled {
    @include label-disabled;
  }

  // stylelint-disable-next-line selector-class-pattern
  .react-datepicker__input-container {
    width: 12rem;
  }

  .radio-indented-content {
    @extend .pref-wrap;

    margin-top: 5px;

    .form-group {
      margin-bottom: 15px;
    }
  }
}

// stylelint-enable no-descending-specificity

.stack-has-checkboxes {
  display: flex;
  flex-wrap: nowrap;

  .form-check {
    margin-top: px-to-rem(2);
    margin-left: px-to-rem(12);
    position: relative;
    z-index: 2;
  }

  .candidate-summary {
    margin-left: px-to-rem(-12);
  }
}

.item-highlight,
.item-de-emphasize {
  background: $primary-variant-lighter;

  .list-unstyled {
    margin-bottom: 0;
  }

  .icon-wrap {
    background-color: $primary-variant-light;
  }

  hr {
    border-color: $primary-variant-darker;
  }
}

// end stack-item styles

// used for dropdown in admin pages
.btn-text-group {
  display: flex;

  .btn {
    flex: 0;
    align-items: center;
  }

  .btn-text-content {
    @extend .ellipsis-block;

    align-items: center;
    max-width: 200px;
    padding-left: 5px;
  }
}

.form-inline {
  // adjust the ".btn" within a "form-inline" to match the height of the related
  // text field
  .btn {
    line-height: 1.5;
    padding-top: $input-btn-padding-y;
    padding-bottom: $input-btn-padding-y;
  }
}

.sidebar-title-with-inline-link {
  display: flex;
  align-items: center;

  .btn {
    line-height: 1.1; // to match line-height of parent <h4>
    padding: spacer(1); // add room between text and the button outline focus/active stylefocus
    margin-top: spacer(-1); // offset padding so that button doesn't take up extra space
    margin-bottom: spacer(-1); // offset padding so that button doesn't take up extra space
  }
}

// status selection module  --------------------------
// module of buttons that indicate the candidate's post-acceptance status

.post-accept-status-wrap {
  margin-bottom: 5px;

  .btn {
    // margin-bottom to keep buttons separated when they wrap
    margin-bottom: 5px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

// end post-accept status buttons

.candidate-status-header {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
}

@include media-breakpoint-down(sm) {
  .pagination {
    .btn-numbered-page:not(.active),
    .btn-prev-more,
    .btn-next-more {
      display: none;
    }
  }
}

// end pagination styles

// messaging styles --------------------------
// used for back-and-forth messaging
// such as Q&A sections

.messaging-thread {
  border: 1px solid $primary-variant-darker;
  padding: 12px;
  max-height: 300px;
  overflow-y: scroll;
  font-size: 12px;
  margin-bottom: -1px;

  &.no-scroll {
    max-height: none;
    overflow-y: auto;
  }
}

.message-question {
  color: $gray-darker;
}

// profile styles for Company Profile pages

.hover-outline {
  &:hover {
    cursor: pointer;
    outline: 1px solid $primary-variant-light;
  }
}

.profile-panel {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 26px;

  .material-symbols-outlined {
    color: $scout-action;
    position: absolute;
    top: 10px;
    right: -4px;
    padding: 4px;
    background-color: $white;
    font-weight: 400;
    border: 1px solid $scout-action;
    border-radius: 50%;
  }
}

.profile-panel-edit {
  .edit-header {
    @extend .h3;

    height: 2.1em;
  }
}

// dropdown styles --------------------------

// change orientation of dropdown menu content
// default behavior is to have menu anchored on the left so that text flows out
// to the right adding this "swap" class flips that and anchors the menu to the
// right
@include media-breakpoint-down(xs) {
  .swap-menu-to-left-xs + .dropdown-menu {
    left: auto;
    right: 0;
  }
}

// scoping these styles to "dropdown-toggle" with "btn-block"
// "btn-block" probably isn't necessary but
// it's safer and requires less QA for right now
.dropdown-toggle {
  &.btn-block {
    .dropdown-content {
      float: left;
    }

    .caret-wrap {
      float: right;
    }
  }
}

// This is added to combat disabled default styling for bootstrap 4 dropdowns,
// should only be seen in situations where there is a disabled item in a
// dropdown
.dropdown-item {
  padding: px-to-rem(3) px-to-rem(24);
}

.candidate-status-selector-dropdown-toggle {
  color: $scout-primary;
  border-color: $primary-variant-darker;
  font-size: 12px;
}

.dropdown-header {
  font-weight: $dropdown-header-font-weight;

  h2 {
    font-size: $font-size-sm;
    font-weight: $font-weight-semibold;
    margin-top: 0;
    margin-bottom: 0;
  }
}

// end dropdown styles --------------------------


// ============================================================ //
// States //
// statuses, ui states
// ============================================================ //

// styling for job/candidate states --------------------------
.job-status {
  color: $brand-success;
}

.status-inactive {
  color: $inactive-text;
}

.status-unknown,
.status-pending,
.status-viewed,
.status-shared,
.status-interested,
.status-notinterested,
.status-screening,
.status-interview,
.status-finalist,
.status-offer,
.status-onhold,
.status-expiring,
.status-waitingforfeedback {
  color: $scout-status;
}

.status-hired,
.status-employerhired,
.status-feedbacksent {
  color: $brand-success;
}

.status-rejected,
.status-expiring-soon,
.status-closed {
  color: $brand-danger;
}

.apply-first-message {
  color: $brand-warning;
  margin: 0 0 10px;
}

.candidate-status-pending {
  border: 1px solid $scout-primary;
}

// for the iframe containing a resume or additional file

.attached-doc-frame {
  width: 100%;
  height: 800px;
  border: 1px solid $primary-variant-dark;
  background-color: $primary-variant-lighter;
  transition: background-color 300ms ease-in-out;
  position: relative;
  overflow: hidden;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: $scout-primary;
  text-size-adjust: 100%;
  will-change: top, left, width, height;
}

// specific to 3i

.resume.parsed {
  height: 70vh;
  overflow-y: scroll;
}

.scroll-box-y {
  overflow-y: scroll;
  max-height: 250px;
}

.container-header-filter {
  display: inline-block;
  padding-top: 6px;
  padding-bottom: 6px;
}

.checkbox-only-my-jobs {
  display: inline-block;
  margin-left: 10px;
}

.not-recruiter-visible-title {
  display: block;
  font-style: italic;
}

// form styles

.error-form-field {
  border-color: $brand-danger;
}

.image-field {
  margin-left: 10px;
}

.field-auto-width {
  .form-control {
    width: auto;
  }
}

.italic-form-label {
  font-weight: 400;
  font-style: italic;
}

.error-form-message {
  color: $brand-danger;
}

.control-label.label-text-disabled {
  @include label-disabled;
}

.multi-option-group-has-text-field {
  > .radio {
    margin-top: 10px;
  }
}

.radio-group {
  .radio:first-of-type {
    margin-top: 0;
  }
}

.checkbox {
  .label-text-disabled {
    @include label-disabled;
  }
}

// 3rd party overrides // --------------------------------------------------

// control is too nested for bootstrap's .has-error to apply
// stylelint-disable-next-line selector-class-pattern
.has-error .Select .Select-control {
  border-color: $brand-danger;
}


// styling for invoicing field admin --------------------------

.checkbox-label-admin-invoicing {
  font-weight: 400;
  padding-left: 20px;
  margin-bottom: 10px;
}

.checkbox-label-admin-invoicing-disabled {
  color: $primary-variant-light;
}

.checkbox-label-admin-invoicing input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
}

.edit-admin-field {
  margin-top: 5px;
  margin-bottom: 5px;

  button {
    padding-top: 0;
  }
}

// styling for pausing reasons admin --------------------------
.checkbox-label-inline-admin {
  font-weight: 400;
  padding-left: 20px;
  margin-bottom: 10px;
}

.checkbox-label-inline-admin input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
}

.config-reasons-link a {
  padding-top: 10px;
  display: block;
}

// admin tables --------------------------
.col-locations {
  width: 160px;
}

.job-qualification-dropdown {
  min-width: 90px;
}

// styling for placement form --------------------------

.placement-step {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  width: 80px;
  position: relative;
  padding: 0 10px;
}

.placement-override-container {
  margin-bottom: 3px;
  display: flex;
}

.placement-overridden-value {
  text-decoration: line-through;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 1 auto;
}

.placement-override-label {
  color: $scout-status;
  font-weight: 700;
  margin-left: 5px;
  flex: 1;
}

.placement-override-container + input {
  margin-top: 4px;
}

// used attribute selector "type-'text'" because of difficulties
// adding a class directly to the input itself
.placement-salary-field {
  input[type="text"] {
    width: 125px;
  }
}

.text-highlight-danger,
.cancellation-highlight {
  color: $brand-danger;
}

.escalation-highlight {
  color: $brand-danger;
  margin-top: 10px;
}

.escalation-notes {
  padding: 5px;
  border: 1px solid $brand-danger;
}

.escalation-notes b {
  color: $brand-danger;
}

.start-date-info {
  color: $scout-primary;
  border: 1px solid $scout-primary;
  padding: 1em;
}

.start-date-warning {
  color: $brand-warning;
  border: 1px solid $brand-warning;
  padding: 1em;
}

.arrow-list li {
  margin-right: 24px;

  // add the arrow after each item
  &::after {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    background-image: url(icons/sprite.svg), none;
    background-size: 129px;
    background-position: -21px -278px;
    bottom: 12px;
    right: -25px;
  }

  // hide arrow for the last item
  &:last-child::after {
    background-image: none;
  }
}

.arrow-list .icon-inactive::after {
  @extend .icon-inactive;
}

.job-listing-description {
  border: 1px solid $primary-variant-darker;
  margin-bottom: spacer(4);
  padding: spacer(1) spacer(2);

  h1,
  h2 {
    font-size: 18px;
  }

  h3,
  h4 {
    font-size: 16px;
  }
}

// so that the "Why?" after "Worker Email Address" will be in-line
.after-label-inline div {
  display: inline;
}

// For reference
// @media (min-width: $screen-sm-min) {}
// @media (min-width: $screen-md-min) {}
// @media (min-width: $screen-lg-min) {}
// @media (max-width: $screen-xs-max) {}

// styles for responsiveness rating UI --------------------------
// includes response-rating-list and scouticon- works
.response-rating-list {
  white-space: nowrap;
  display: inline-block;
  padding-right: 10px;
  padding-top: 10px;
  position: relative;
  bottom: -2px;

  li {
    @extend .m-x-0;
  }
}

.navbar-nav {
  .response-rating-list {
    padding-right: 0;
    padding-top: 1px;
  }
}

.navbar-dark {
  .navbar-text {
    // needed to override default Bootstrap text color
    color: $white;
  }

  .navbar-nav {
    > li {
      button {
        // color: $navbar-inverse-link-color;
        line-height: 20px;

        &:hover,
        &:focus {
          // color: $navbar-inverse-link-hover-color;
        }
      }
    }
  }
}

.navbar-links {
  width: 100%;
  justify-content: flex-end;
}

.navbar-search {
  max-width: 370px;
  width: 100%;
}

.scouticon-text {
  font-size: 12px;
  font-weight: 400;
  top: 3px;
  text-align: center;
}

.pref-wrap {
  margin-left: 20px;
}

.nested-options {
  @extend .pref-wrap;

  .radio + & {
    margin-top: -5px;
  }
}

.landing-page {
  margin: 30px;

  .logo {
    width: 15em;
  }

  .landing-page-head {
    margin-bottom: 30px;
  }
}

// used for 'star' rating
.rating-inline {
  color: $star-default;
  display: inline-block;
  padding-left: 0;
  position: relative;
  line-height: 1;
  white-space: nowrap;
  top: 3px;

  &.hiring {
    color: $star-hiring;
  }

  &.staffing {
    color: $star-staffing;
  }

  &.poor {
    color: $star-poor;
  }

  &.fair {
    color: $star-fair;
  }

  &.good {
    color: $star-good;
  }

  // pull stars closer together
  .material-symbols-outlined:not(:first-child) {
    margin-left: -2px;
  }
}

.rating-inline-text {
  color: $no-ratings-text;
}

.emphasis-text {
  font-style: italic;
  color: $scout-status;
}

.inline-fields-row {
  margin-right: -5px;
  margin-left: -5px;
}

.inline-fields {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0 5px;
  }

  .flex2 {
    flex: 2 0 150px;
  }

  .flex1 {
    flex: 1 0 75px;
  }

  .flex0 {
    flex: 0 0 25px;
  }
}

.btn-cell {
  align-self: flex-start;

  .svg-btn {
    display: block;
    margin-top: 29px;
  }
}

.inline-checkbox-spacing {
  margin-top: 22px;
  margin-bottom: 20px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 10px;
  }
}

.listing-layout-side {
  margin-bottom: 20px;

  > div {
    padding: 0 1em;
  }

  .item-highlight {
    border: 1px solid $primary-variant-darker;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;

    // min-width so that salary fields remain wide enough to show full values
    min-width: 21em;
    width: 33%;
  }
}

.listing-layout-main {
  > div {
    border: 1px solid $primary-variant-darker;
    margin-bottom: 1em;
    padding: 0 1em 1em;
  }

  @include media-breakpoint-up(sm) {
    flex: 1;
    margin-left: 1em;
  }
}

// pre-screen classes when questions are structured in
// the Bootstrap grid system
.pre-screen-question-wrap {
  border-top: 1px solid $primary-variant-light;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;

  .row {
    // target "-top" and "-bottom" specifically
    // to not affect the left/right margins already on ".row"
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.share-history-notes-modal-content-wrapper {
  @include force-text-wrap;

  > div {
    margin-bottom: 10px;
  }
}

.tab-content {
  .popover-trigger {
    @extend %underline;
  }
}

.nav-tabs {
  .badge {
    margin-top: -3px;
    margin-left: 7px;
  }
}

.main-tabs,
.candidate-tabs {
  h2 {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .nav-tabs {
    margin-bottom: -1px;

    // position: relative so that z-index will apply
    position: relative;
    z-index: 1;
  }

  .tab-content {
    padding-top: 0;
  }

  .tab-pane {
    @include clearfix;

    border: 1px solid $nav-tabs-border-color;
    padding: 15px;
  }
}

.sender-notes-modal-wrapper {
  margin-top: 5px;

  > span {
    font-style: italic;
  }

  .sender-notes-modal-link {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}

.share-history {
  @include force-text-wrap;

  display: flex;
  margin-bottom: 10px;

  .share-history-icon {
    margin: 4px 1em 0;
    min-width: 26px;
    text-align: center;

    .svg-icon {
      height: 17px;
      width: 16px;
    }

    path {
      stroke-width: 8;
    }
  }

  .share-history-content {
    border-left: 1px solid $primary-variant-light;
    padding: 0 1em;
    width: 100%;
  }

  .shared-name,
  .shared-feedback {
    margin-bottom: 5px;
  }

  .shared-feedback,
  .shared-dates,
  .sender-notes-modal-wrapper {
    font-size: 12px;
  }

  .share-date {
    font-style: italic;
    margin-bottom: 2px;
  }
}

.shared-feedback-flex {
  display: flex;

  .shared-feedback-content {
    flex: 1 0 0;
    min-width: 0;
  }
}

// pre-screen classes for when the questions
// are rendered on top of each other
.pre-screen-question-list {
  > div {
    padding-top: 5px;

    + div {
      border-top: 1px solid $primary-variant-lighter;
      padding-top: 10px;
    }
  }
}

.shares-feedback-counter {
  line-height: 1;
  font-size: 20px;
  font-weight: 700;
  margin-top: 6px;

  .svg {
    margin-left: 13px;
    top: 1px;
    width: 16px;
    height: 17px;

    line {
      stroke-width: 8px;
    }

    path {
      stroke-width: 8px;
    }
  }
}

.inline-text-icons {
  display: flex;
  align-items: center;

  > span {
    margin-right: 5px;
  }

  .icon-list {
    display: flex;

    > .spike-badge,
    > .svg {
      margin-right: 4px;
    }
  }
}

.share-feedback-count-wrap {
  display: flex;
  margin-top: 10px;
  margin-bottom: 2px;

  .share-feedback-count-item {
    @extend .stack-item-border;

    border-radius: 5px;
    margin-right: 10px;
  }

  .share-count-item {
    min-width: 75px;
    min-height: 50px;

    > div {
      margin-left: 10px;
    }
  }

  .feedback-count-item {
    min-width: 130px;
    min-height: 50px;

    > span {
      margin-left: 4px;
    }
  }
}

.shares-feedback-counter-text {
  color: $primary-variant-darkest;
  font-size: 12px;
  margin-left: 10px;
}

.margin-bottom-2px {
  margin-bottom: 2px;
}

.error-border {
  border-color: $brand-danger;
}

.pay-rate-description {
  margin-bottom: 5px;
  position: relative;
  top: -20px;
}

.row-billable-activities {
  @include media-breakpoint-up(sm) {
    align-items: baseline;
    display: flex;
  }
}

.billable-activity-entry {
  border-bottom: 1px solid $primary-variant-lighter;
  margin-bottom: 15px;
}

.display-none {
  display: none;
}

@include media-breakpoint-down(xs) {
  .m-t-xs-on-child {
    [class^="col"] {
      margin-top: 5px;
    }
  }
}

.worker-onboard-reqs {
  font-size: 12px;
  margin: 10px 0;

  .wor-header {
    border-bottom: 1px solid $primary-variant-light;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 7px;
    padding-bottom: 7px;
  }

  .worker-onboard-reqs-item {
    display: flex;
    margin-bottom: 7px;
  }

  .wor-status {
    display: inline-block;
    min-width: 6.5em;
    padding-right: 5px;
  }

  .wor-req {
    flex: 1 0 0%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 66%;
  }
}

.placement-wors-wrap {
  .worker-onboard-reqs,
  .wor-header {
    font-size: $font-size-base;
    margin-top: 0;
  }

  .worker-onboard-reqs {
    margin: 0 0 25px;

    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }
  }

  .wor-header {
    border: 0;
    padding-bottom: 0;
  }
}

.job-exclusivity-table {
  .form-check {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// force width of Fee Split column in Admin
.col-fee-split {
  width: 8.2em;

  @include media-breakpoint-up(sm) {
    width: 7.9em;
  }
}


// styles for new Scout Listing page --------------------------

.listing-layout {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    padding: 0;
  }
}

.listing-copy-job {
  border-bottom: 1px dashed $primary-variant-darker;
  padding-bottom: 10px;
}

.copy-job-fields {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .dropdown {
    position: absolute;
    top: 2.6em;
    width: 100%;
  }
}

.copy-jobs-no-results {
  padding: 15px 20px;
  text-align: center;

  p:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(xs) {
    width: $copy-job-field-width-xs-min;
  }

  @include media-breakpoint-up(sm) {
    width: $copy-job-field-width-sm-min;
  }

  @include media-breakpoint-up(md) {
    width: $copy-job-field-width-md-min;
  }
}

.copy-job-dropdown-menu {
  max-height: 314px;
  max-width: 100%;
  overflow-y: auto;

  // maintain Bootstrap's selectors to override its default white-space value
  > li > a {
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: normal;
  }
}

// styles for new Placements List page --------------------------

.placement-page-list {
  .stack-item {
    margin-bottom: 10px;
  }

  .stack-headline {
    font-size: $font-size-base;
    margin-top: 0;
  }

  .stack-detail {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.temp-sourcing-fee-split-preferences {
  .table {
    width: auto;
  }

  .table > tbody > tr > td {
    padding-top: 12px;
    padding-bottom: 12px;
    vertical-align: middle;
  }

  .read-only {
    tr:last-child {
      border-bottom: 1px solid $table-border;
    }
  }

  .editable {
    .new-sourcing-percent {
      td {
        vertical-align: top;
      }
    }

    .input-group,
    .help-block {
      width: 110px;
    }

    // Has the same visual effect as "vertical-align: middle", but allows the
    // content to maintain its position when the height of the element changes
    // e.g. when we have error messages.
    .align-middle-keep-top {
      line-height: 33px;
    }
  }
}

.temp-sourcing-fee-display {
  margin-top: 8px;
}

// creates dashed line divider between groups of Providers in Select Providers
// modal on listing page
.providers-section {
  .checkbox-tree + .checkbox-tree {
    border-top: 1px dashed $primary-variant-light;
    margin-top: 20px;
    padding-top: 20px;
  }
}

.textarea-wrap {
  textarea {
    min-height: 56px;
  }
}

// styles for <JobLocations /> ----------------------------------

.job-locations-container {
  margin-bottom: 10px;

  .svg-btn {
    vertical-align: -2px;
  }

  .badge {
    border-radius: 0;
    vertical-align: 2px;
    background-color: $cerulean;
  }
}

.placement-location {
  display: flex;
  align-items: flex-end;

  .placement-locations {
    flex: 1 1 0;
  }

  .placement-zip {
    margin-left: 7px;
    flex: 0 0 7.5em;

    .control-label {
      font-style: italic;
      font-weight: $font-weight-normal;
    }
  }
}

// Form styles from scout-ui
// Didn't want to pull over all form styles yet.
.label-wrap {
  font-weight: $font-weight-bold;
  margin-bottom: 1px;
}

.field-group-description {
  font-size: $font-size-sm;
  margin-bottom: 10px;
}

.currency-and-salary-range {
  display: flex;
  flex-direction: column;

  .dropdown,
  .btn-dropdown {
    width: 100%;
  }

  .dropdown {
    margin-bottom: 3px;
  }

  .salary-range-wrap {
    display: flex;
    align-items: center;
  }

  .dash-separator {
    margin: 0 1rem;
  }
}

.marketplace-tabs {
  .nav-tabs {
    margin-bottom: 5px;
  }

  .tab-content {
    padding-top: 0;
  }

  .marketplace-candidates-list {
    min-width: 600px;
    max-width: 1500px;
  }

  .marketplace-candidate-name {
    display: block;
  }

  .marketplace-candidates-intro {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .marketplace-candidate-req-id {
    @include ellipsis;

    max-width: 200px;
  }
}

// set a width for zip code fields
.field-zip-code {
  .form-control {
    width: 7.5em;
  }
}

// set width on percent field that has "%" at the end
.input-group-with-percent {
  width: 5.2em;
}

.copy-to-clipboard {
  cursor: pointer;

  .svg-clipboard {
    margin-bottom: -2px;
  }
}

.disability-status-popover {
  font-size: $font-size-base;
  padding: spacer(3);

  .disability-status-popover-list {
    display: flex;

    > ul:not(:first-child) {
      margin-left: spacer(5);
    }
  }
}

.fee-split {
  color: $fee-split;
}

.label-with-edit {
  display: flex;
  align-items: flex-start;

  .svg-btn {
    margin-top: spacer(1);
    margin-left: spacer(1);
  }

  .more-info-popover {
    margin-top: -3px;
  }
}

.client-profile-field {
  .form-control {
    max-width: spacer(100);
  }
}

.client-week-start-day-select {
  .form-control {
    max-width: spacer(100);
  }
}

.client-profile-select {
  .form-control {
    width: auto;
  }
}

// announcement tab styles
.announcement {
  .announcement-text {
    border: 1px solid $primary-variant-light;
    padding: 7px 0 0 10px;
  }

  .date-latest {
    color: $scout-status;
  }

  .btn-danger-link {
    @extend %underline;

    color: $danger;
    padding: 2px;
    margin-bottom: 2px;
  }
}

.scroll-y-wrap {
  height: spacer(115);
  overflow-y: auto;
  position: relative;
}

.scroll-selected-recruiters {
  border-top: 1px solid $primary-variant-light;
  border-bottom: 1px solid $primary-variant-light;
  max-height: 35rem;
  overflow-y: auto;
  padding: spacer(1) spacer(1) spacer(1) 0;
}

.location-input-fields {
  .caret {
    display: none;
  }

  // stylelint-disable-next-line no-descending-specificity
  .form-group {
    margin-bottom: spacer(2);
  }

  .form-static-output {
    padding-bottom: spacer(1.5);
  }

  .select-wrap.disabled {
    .form-control {
      border-color: $input-color-disabled;
    }
  }
}

.icon-row {
  display: flex;
  gap: spacer(2);
}

.search-type-popover {
  font-size: 14px;
}

.search-divider {
  border: 0;
  height: 1px;
  color: $scout-primary;
  background-color: $scout-primary;
}

// stylelint-disable selector-class-pattern
.Select {
  font-size: $font-size-base;
  max-height: 93vh;
  background-color: $body-background;

  .Select__menu {
    max-height: 93vh;
    background-color: $body-background;
    word-break: break-word;
  }

  .Select__menu-list {
    max-height: 93vh;
    border: solid;
  }

  .Select__group {
    border: 0;
    padding-top: 0.8rem;
    margin: 0;
  }

  .Select__group-heading {
    width: 100%;
    position: sticky;
    border: 0;
    top: 0;
    background-color: $body-background;
    color: $scout-primary;
    font-size: inherit;

    > div {
      display: flex;
      align-items: center;
    }
  }

  .Select__option--is-selected {
    font-weight: $font-weight-normal;
  }

  .Select__option--is-selected:not(.Select__option--is-focused) {
    background-color: $body-background;
    color: $scout-primary;
  }

  .Select__input {
    opacity: 1 !important;
  }

  .inactive-icon {
    font-size: 1.5rem;
    font-weight: 200;
    font-variation-settings: "opsz" 24;
  }
}

// stylelint-enable selector-class-pattern
.candidate-actions {
  .btn-row {
    margin-top: 5px;
    padding-top: 5px;

    .btn + .btn {
      margin-left: 1px;
    }
  }
}


// styles for delaying element from rendering
.delay {
  opacity: 0;
  animation: show-element 2s forwards;
}

@keyframes show-element {
  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

