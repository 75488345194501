// coding:utf-8
// Copyright 2013-2023 Scout Exchange, LLC. All Rights

// mixin defaults = scout-icon-base(
//   $float: left, $margin: 0, $padding: 0,
//   $height: 15px, $width: 15px,
//   $backPosition: 0 0, $backRepeat: no-repeat
// )

@use "sass:math";

.scouticon {
  background-image: url(icons/sprite.png);
}

.icon-inactive {
  opacity: 0.5;
}

.svg-announcement {
  height: 100%;
  padding: 2px 3px;
  width: 100%;
}

.svg-announcement-path {
  stroke: $scout-status;
  stroke-width: 1;
}

.svg-announcement-fill {
  fill: $white;
}

.badgeicon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-image: url(icons/sprite.svg);
  background-size: 80px;
}

.badgebutton {
  &:hover,
  &:focus,
  &.focus {
    background-color: $primary-variant-lighter;
  }

  &:active,
  &.active {
    background-color: $scout-primary;
  }

  .inactive &,
  &.disabled,
  &[disabled] {
    background-color: $primary-variant-light;
    border-color: $primary-variant-light;
  }
}

// utility adjustments for badgeicons
.badge-link {
  width: 5em;
  text-align: center;
  margin-right: 15px;
  font-size: 12px;
  float: left;
  cursor: pointer;
  color: $scout-primary;
  position: relative;

  &.inactive {
    color: $inactive-text;
    cursor: default;
    text-decoration: none;
  }

  .badge {
    position: absolute;
    left: calc(50% + 5px);
    top: -5px;
    text-align: center;
    font-weight: 400;
  }

  &.with-divider {
    margin-right: 35px;

    &::after {
      border-left: 1px solid $scout-primary;
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      right: -18px;
    }
  }
}

.badgebackwhite {
  background-color: $white;
}

// combination of some utilities for
// regularly used "round button" style
.badge-border-round {
  border-radius: 50%;
  border: 1px solid $scout-primary;
}

.badge-border-round-sm {
  @extend .badge-border-round;

  width: 16px;
  height: 16px;
}

.icon-align-with-text {
  bottom: -4px;
  margin-left: 5px;
  position: relative;
  display: inline-block;
}

.icon-align-with-text-no-margin {
  @extend .icon-align-with-text;

  bottom: -5px;
  margin-left: 0;
  background-color: $scout-primary;
}

.badge-group {
  margin-top: 15px;

  > div {
    display: flex;

    + div {
      margin-top: 3px;
    }
  }
}

.badge-group-icon-text {
  font-size: 12px;
  margin-top: 3px;
  margin-left: 7px;
}

.badgeicon-resume {
  @include badge-icon-with-hover($top: -1px);
}

.badgeicon-share {
  @include badge-icon-with-hover($top: -41px);
}

.badgeicon-share-history {
  @include badge-icon-with-hover($top: -360px);
}

.badgeicon-document {
  background-size: 163px;
  background-position: -25px -20px;
  width: 32px;
  height: 41px;
}

.badgeicon-documentcheck {
  background-size: 163px;
  background-position: -25px -183px;
  width: 32px;
  height: 41px;
}

.badgeicon-checkmark {
  background-size: 163px;
  background-position: -25px -263px;
  width: 32px;
  height: 41px;
}

.badgeicon-diversity {
  background-position: -13px -253px;
  width: 16px;
  height: 16px;
}

.badgeicon-preferred {
  background-position: -13px -293px;
  width: 16px;
  height: 16px;
}

.badgeicon-recommended {
  background-position: -13px -333px;
  width: 16px;
  height: 16px;
}

.badgeicon-diversity-white {
  @extend .badgeicon-diversity;

  background-position: -53px -253px;
}

.badgeicon-preferred-white {
  @extend .badgeicon-preferred;

  background-position: -53px -293px;
}

.badgeicon-recommended-white {
  @extend .badgeicon-recommended;

  background-position: -53px -333px;
}

.badgeicon-chevron-right {
  @include badge-icon-with-hover($leftStatic: -8px, $leftHover: -48px, $top: -448px);

  width: 23px;
  height: 23px;
}

.badgeicon-chevron-left {
  @include badge-icon-with-hover($leftStatic: -9px, $leftHover: -49px, $top: -408px);

  width: 23px;
  height: 23px;
}


// ============================================================ //
// Inline SVG Icons
// Styling for the icons where SVG code is directly in the component
// ============================================================ //

// SVG general/default // --------------------------------------------------

line,
path {
  fill: transparent;
  stroke: $scout-primary;
  stroke-width: 4;
}

// SVG  --------------------------------------------------

.svg-icon-wrap {
  text-align: center;
}

.svg-icon {
  display: block;
  margin: 0 auto;
}

.svg-icon-text {
  font-weight: $font-weight-bold;
}

// creates a circle border
.svg-badge {
  @include circle-border;

  line,
  path {
    stroke: $scout-action;
  }

  &.has-background {
    background-color: $white;
  }
}

.svg-badge-static {
  @include circle-border($color: $scout-primary);

  line,
  path {
    stroke: $scout-primary;
  }

  &.has-background {
    background-color: $white;
  }
}

.svg-envelope {
  path {
    stroke-width: 1px;
  }

  &-body,
  &-top {
    fill: transparent;
  }
}

.svg-paw-print {
  height: 12px;
  width: 12px;

  ellipse,
  path {
    fill: $scout-primary;
    stroke: transparent;
  }
}

.thumb-positive-wrap {
  color: $svg-positive;

  .svg-thumbs-up {
    line {
      stroke: $svg-positive;
    }

    path {
      stroke: $svg-positive;
    }
  }

  .svg-icon-text {
    color: $svg-positive;
  }
}

.thumb-negative-wrap {
  color: $svg-negative;

  .svg-thumbs-down {
    line {
      stroke: $svg-negative;
    }

    path {
      stroke: $svg-negative;
    }
  }

  .svg-icon-text {
    color: $svg-negative;
  }
}

.thumb-no-response-wrap {
  color: $primary-variant-light;

  .svg-thumbs-up {
    transform: scale(-1, 1);

    line {
      stroke: $primary-variant-light;
    }

    path {
      stroke: $primary-variant-light;
    }
  }

  .svg-icon-text {
    color: $primary-variant-light;
  }
}

.svg-xs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $svg-icon-xs-size;
  width: $svg-icon-xs-size;

  .svg {
    width: 100%;
    height: 100%;
    padding: math.div($svg-icon-xs-size, 4);
  }
}

.svg-sm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $svg-icon-sm-size;
  width: $svg-icon-sm-size;

  .svg {
    width: 100%;
    height: 100%;
    padding: $svg-icon-sm-padding;
  }

  .svg-discussion {
    padding: 5px $svg-icon-sm-padding 3px;
  }
}

.svg-lg {
  display: inline-block;
  height: $svg-icon-lg-size;
  width: $svg-icon-lg-size;
  padding: math.floor(math.div($svg-icon-lg-size, 4));
}

.inline-svg {
  display: flex;

  &.with-text {
    .svg-wrap {
      height: 15px;
    }

    .svg {
      flex: 0;
      margin-left: 5px;
      position: relative;
      top: 1px;
      width: 13px;
    }

    path {
      stroke-width: 8;
    }
  }
}

.svg-edit-pencil {
  width: 16px;
  height: 16px;
}

.svg-pencil-stroke {
  fill: transparent;
  stroke: $scout-action;
  stroke-width: 1px;
}

.svg-pencil-fill {
  fill: $scout-action;
  stroke-width: 0;
}

// stylelint-disable no-descending-specificity

// sets base colors and styles for "svg-btn" states
.svg-btn {
  color: $scout-primary;
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0;

  + .svg-btn {
    margin-left: 1em;
  }

  .badge-text {
    display: block;
    margin-top: 3px;
  }

  .svg-edit-pencil {
    height: 100%;
    width: 100%;
  }

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    .svg-badge {
      background-color: $svg-hover-bg;
      border-color: $svg-hover-border;

      line {
        stroke: $svg-hover-stroke;
      }

      path {
        fill: $svg-hover-fill;
        stroke: $svg-hover-stroke;
      }

      .svg-left-arrow {
        path {
          fill: transparent;
        }
      }
    }

    .thumbs-down-stroke,
    .thumbs-up-stroke {
      fill: $white;
    }

    .svg-edit-pencil {
      .svg-pencil-fill {
        fill: $scout-action;
      }
    }
  }

  &:active,
  &.active {
    .svg-badge {
      background-color: $svg-active-bg;
      border-color: $svg-active-border;

      line {
        stroke: $svg-active-stroke;
      }

      path {
        fill: $svg-active-fill;
        stroke: $svg-active-stroke;
      }

      .svg-left-arrow {
        path {
          fill: transparent;
        }
      }
    }

    .thumbs-down-stroke,
    .thumbs-up-stroke {
      fill: transparent;
    }

    .svg-edit-pencil {
      .svg-pencil-fill {
        fill: $white;
      }
    }
  }

  &.disabled,
  &[disabled] {
    color: $svg-disabled-color;
    cursor: not-allowed;
    pointer-events: none;

    &:hover,
    &.hover,
    &:focus,
    &.focus {
      font-weight: 400;
      text-decoration: none;

      .thumbs-down-stroke,
      .thumbs-up-stroke {
        fill: transparent;
      }
    }

    .svg-badge {
      background-color: $svg-disabled-bg;
      border-color: $svg-disabled-border;

      line {
        stroke: $svg-disabled-stroke;
      }

      path {
        fill: $svg-disabled-fill;
        stroke: $svg-disabled-stroke;
      }

      .svg-left-arrow {
        path {
          fill: transparent;
        }
      }
    }

    .svg-edit-pencil {
      .svg-pencil-fill {
        fill: $svg-disabled-color;
      }
    }
  }

  .badge {
    background-color: $scout-status;
    font-size: 12px;
    padding: 2px 5px;
    position: absolute;
    top: 0;
    left: 19px;
    z-index: 10;
  }
}

// stylelint-enable no-descending-specificity

.thumb-positive {
  &.svg-btn {
    &:active,
    &.active {
      .svg-badge {
        background-color: $svg-positive;
        border-color: $svg-positive;

        line {
          stroke: $svg-active-stroke;
        }

        path {
          stroke: $svg-active-stroke;
          fill: transparent;
        }
      }
    }
  }
}

.thumb-negative {
  &.svg-btn {
    &:active,
    &.active {
      .svg-badge {
        background-color: $svg-negative;
        border-color: $svg-negative;

        line {
          stroke: $svg-active-stroke;
        }

        path {
          stroke: $svg-active-stroke;
          fill: transparent;
        }
      }
    }
  }
}

// SVG icon-specific styles // ------------------------------------------------

.close-x-stroke {
  stroke-width: 1;
}

.intake-call-link {
  vertical-align: top;
}

// icon.jsx > Audio
.audio-stroke {
  stroke-width: 1;
}

.arrow-stroke {
  stroke-width: 2;
}

.discussion-path {
  stroke-width: 1;
}

// general styles for the backing shapes of svg icons ("badge" and "award" so
// far)s
.svg-shape-bg {
  fill: $svg-non-link-bg;
  stroke: transparent;
}

// "Diverse Candidates" award-style (spikey) icon
.svg-diversity {
  width: 23px;
}

.svg-diversity-path {
  fill: $white;
  stroke: transparent;
}

// "Preferred Vendor" award-style (spikey) icon
.svg-preferred {
  width: 23px;
}

.svg-preferred-path {
  fill: transparent;
  stroke: $white;
  stroke-width: 2;
}

// "Scout Recommended Recruiter" badge-style icon
.svg-recommended {
  width: 23px;
}

.svg-recommended-group,
.svg-recommended-path,
.svg-recommended-ellipse {
  fill: $white;
  stroke: transparent;
}

.svg-checkmark {
  polyline {
    fill: transparent;
    stroke: $scout-primary;
    stroke-width: 2;
  }
}

.svg-lightbulb {
  .lightbulb-top,
  .lightbulb-sc-top,
  .lightbulb-sc-mid,
  .lightbulb-sc-bot, {
    fill: transparent;
    stroke: $alert-hint-icon;
    stroke-width: 1px;
  }

  .lightbulb-fil-stem,
  .lightbulb-fil-right,
  .lightbulb-fil-left,
  .lightbulb-arm-right,
  .lightbulb-arm-left {
    fill: transparent;
    stroke: $alert-hint-icon;
    stroke-width: 0.5px;
  }

  .lightbulb-base {
    fill: $alert-hint-icon-faint;
    stroke: transparent;
    stroke-width: 0;
  }
}

.svg-sample-tooltip-arrow {
  position: absolute;
  width: 20px;
  height: 20px;
}

.svg-sample-tooltip-arrow-inner {
  fill: $white;
  stroke-width: 0;
}

.svg-sample-tooltip-arrow-outer {
  fill: transparent;
  stroke-width: 1;
  stroke: $scout-primary;
}

.svg-info-icon {
  height: px-to-rem(16);
  width: px-to-rem(16);

  .svg-info-circle {
    fill: $white;
    stroke: $onboard-icon;
    stroke-width: 1px;
  }

  .svg-info-i {
    fill: $onboard-icon;
    stroke: transparent;
  }

  &.svg-info-icon-light {
    .svg-info-circle {
      fill: $white;
      stroke: $scout-action;
    }

    .svg-info-i {
      fill: $scout-action;
    }
  }
}

.svg-clipboard {
  height: 16px;
  width: 16px;

  .svg-clipboard-path {
    stroke: $scout-action;
    stroke-linecap: square;
    stroke-width: 1px;
  }

  .svg-clipboard-rect {
    fill: transparent;
    stroke: $scout-action;
    stroke-width: 1px;
  }

  .svg-clipboard-arrowhead {
    fill: $scout-action;
    fill-rule: evenodd;
    stroke-width: 0;
    stroke: transparent;
  }
}
