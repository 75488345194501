// coding:utf-8
// Copyright 2013-2018 Scout Exchange, LLC. All Rights

// Typography

h1,
.h1 {
  font-size: $h1-font-size;
  font-weight: $font-weight-light;
  line-height: $font-line-height-h1;
  margin: 0;
}

// override Bootstrap color
h3,
.h3 {
  a {
    small,
    .small {
      font-size: 85%;
      color: inherit;
    }
  }
}

.with-bottom-border {
  border-bottom: 1px solid $primary-variant-dark;
  padding-bottom: 0.2em;
}

.section-header,
.admin-app-wrap h3 {
  @extend .with-bottom-border;

  margin-top: 45px;
  padding-bottom: 10px;
}

// remove h3 styles when within section-header
.section-header h3 {
  border-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

// a little kludge to prevent certain form labels from wrapping
label.crunched {
  letter-spacing: -0.5px;
}
