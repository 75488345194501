// coding:utf-8
// Copyright 2013-2018 Scout Exchange, LLC. All Rights

// styles for ui/hiring/components/page_title.jsx

.page-title {
  margin-right: auto;
}

.page-label {
  margin-left: 60px;
  align-self: flex-end;
}

.page-header {
  @extend .container;

  border: 0;
  display: flex;
  margin: 20px auto 10px;
  padding: 0 $default-padding;
  position: relative;

  .pager-wrap {
    justify-content: flex-end;
    margin-top: 11px;
    margin-left: auto;
    text-align: right;
  }

  .btn {
    align-self: center;
  }

  &.with-backlink {
    padding-left: 1em;
  }

  &.with-pager {
    flex-wrap: wrap;
    margin-bottom: 0;

    .page-title {
      margin-top: 10px;
      order: 1;
      width: 100%;
    }
  }
}


// == Media Queries
// Use max-width for widths to prevent back button from going off screen.
// On narrow screens, the back button will be pushed in and remain visible.

@include media-breakpoint-up(sm) {
  .page-header {
    .pager-wrap {
      margin-left: 10px;
      order: 1;
    }

    &.with-backlink {
      max-width: $title-container-sm;
      padding-right: $main-section-padding;
      width: auto;
    }

    &.with-pager {
      flex-wrap: nowrap;
      margin-bottom: 10px;

      .page-title {
        margin-top: 0;
        order: unset;
        width: auto;
      }
    }
  }

  .l-main {
    .page-header {
      max-width: 100%;
      padding-right: $main-section-padding;
      padding-left: $main-section-padding;
      width: auto;

      &.with-backlink {
        max-width: 100%;
        padding-left: $default-padding;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .page-header {
    &.with-backlink {
      max-width: $title-container-md;
      width: auto;
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-header {
    &.with-backlink {
      max-width: $title-container-lg;
      width: auto;
    }
  }
}

@include media-breakpoint-up(xl) {
  .page-header {
    &.with-backlink {
      max-width: $title-container-xl;
      width: auto;
    }
  }
}

// stack the .pager-wrap layout between sm-min and lg-min breakpoints
@include media-breakpoint-between(sm, lg) {
  .page-header {
    .pager-wrap {
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-start;
    }

    .pager-text {
      order: 0;
    }
  }
}
