// Copyright 2013-2020 Scout Exchange, LLC. All Rights Reserved.

// ======================================

// NOTE: Bootstrap is a mobile-first framework. I chose to maintain that
// approach. Therefore, the first styles within a selector are meant for the
// mobile view. Styles for larger screens are added with the @media query
// further down

// ======================================

// ================================
// General Layout and Bootstrap Overrides
// ================================

// adjusted breakpoint for nav usage
// included here instead of _scout-variables because
// _scout-variables gets imported first overall before $screen-lg-min is defined
$screen-links-collapse-min: 1200px !default;
$screen-links-collapse-max: $screen-links-collapse-min - 1px;

.skill-wrap {
  .skillcom-logo {
    width: 83px;
  }

  // FIXME: We should eventually use the full color version of the Skill.com
  // logo. The styles below "colorize" the white version of the logo, but it
  // feels kind of hacky.
  .skillcom-logo-white {
    .skillcom-logo-text {
      fill: $skillcom-logo-color-text;
    }

    .skillcom-logo-dot {
      fill: $skillcom-logo-dot;
    }
  }

  .navbar {
    color: $skill-nav-link;

    .svg-external-link {
      path {
        stroke: $skill-nav-link;
      }
    }
  }

  .navbar-dark {
    background-color: $skill-nav-bg;
  }

  // ================================
  // Navigation Header -----
  // includes logo and mobile nav menu button
  // ================================

  .brand-logo {
    border: 0;

    // "display: block" necessary for when the wrapper is an <a>
    display: block;
    margin-left: $navbar-padding-x $navbar-padding-y;
    margin-top: 3px;
    padding-left: 2px;
    width: auto;

    @include media-breakpoint-up(sm) {
      margin-left: 0;
    }
  }

  // mobile navigation toggle button style and animation
  .navbar-toggle {
    border-radius: 50%;
    border-color: $skill-nav-link;
    margin-top: 0;
    margin-right: $navbar-padding-x $navbar-padding-y;
    margin-bottom: 0;
    min-height: $nav-toggle-size;
    min-width: $nav-toggle-size;
    padding: 8px;

    @include media-breakpoint-up(sm) {
      display: block;
      margin-top: -($nav-toggle-size / 2);
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 1;
    }

    @include media-breakpoint-up(lg) {
      display: none;
      position: static;
    }

    .icon-bar {
      background-color: $skill-nav-link;
      height: 1px;
      position: relative;
      width: 14px;

      + .icon-bar {
        margin-top: 3px;
      }

      // rotate the first line
      &:nth-child(2) {
        transition: rotate $alert-ease-type 0.2s, translate $alert-ease-type 0.2s;
        transform: rotate(45deg) translate(3px, 3px);
      }

      // fade out the second line
      &:nth-child(3) {
        transition: opacity $alert-ease-type 0.1s;
        opacity: 0;
      }

      // rotate the third line
      &:nth-child(4) {
        transition: rotate $alert-ease-type 0.2s, translate $alert-ease-type 0.2s;
        transform: rotate(-45deg) translate(3px, -3px);
      }
    }

    &.collapsed {
      .icon-bar {
        // reset the mobile menu to horizontal lines
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  .btn-envelope {
    background: transparent;
    line-height: 1;
    padding: 0;
    width: auto;

    .caret {
      display: none;
    }

    .svg-envelope {
      height: 17px;
      width: 27px;

      path {
        stroke: $skill-svg-stroke;
      }

      &-body,
      &-top {
        fill: $onboard-logo-background;
      }
    }

    &.has-messages {
      .svg-envelope {
        width: 27px;

        path {
          stroke: $skill-svg-stroke;
          fill: $white;
        }
      }
    }

    &:hover {
      .svg-envelope {
        path {
          stroke: $skill-svg-stroke;
        }

        &-body,
        &-top {
          fill: $onboard-logo-background;
        }
      }
    }
  }

  // navbar-actions is the grouping class for all nav tabs and links
  // these styles can apply to nav-tabs and nav-links
  .navbar-actions {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    text-align: left;

    &:first-child {
      padding-top: 6px;
    }

    &:last-child {
      padding-bottom: 6px;
    }

    @include media-breakpoint-up(sm) {
      align-items: center;
      display: flex;
      text-align: left;

      > li {
        position: relative;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    // shared base styles for all <a> elements
    a {
      @include skill-nav-action-basics;

      display: block;

      @include media-breakpoint-up(sm) {
        white-space: nowrap;
      }
    }

    // shared base styles for all .btn-dropdown elements
    .btn-dropdown {
      @include skill-nav-action-basics;

      display: flex;
      align-items: center;
      float: none;

      @include media-breakpoint-up(lg) {
        max-width: $ellipsis-max-width;
        overflow: inherit;
      }

      .caret {
        border-width: $nav-caret-width-base;
        margin-top: 0;
        margin-left: 9px;
        position: static;

        @include media-breakpoint-up(sm) {
          margin-left: 7px;
        }
      }
    }

    // shared base spacing for all <li> elements
    li {
      padding: 3px 6px;

      @include media-breakpoint-up(sm) {
        padding: 0 5px;
      }
    }

    // shared base spacing for all <li> elements within a .dropdown-menu
    .dropdown-menu li {
      padding: 3px 0;

      &:empty {
        padding: 0;
      }

      @include media-breakpoint-up(sm) {
        padding: 3px 6px;
      }
    }
  }

  .navbar-userinfo-wrap {
    .popover-trigger {
      display: block;
      font-size: $font-size-sm;
      max-width: 8em;
      padding: 7px 7px 6px;
      text-align: right;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        max-width: none;
      }
    }

    .rating-inline-text {
      color: $no-ratings-text;
      font-size: $font-size-sm;
      white-space: nowrap;

      @include media-breakpoint-up(sm) {
        font-size: 11px;
      }
    }
  }

  // styles for label/badges that appear within .navbar-tabs
  .navbar .label {
    @include navItemFontSize;

    background-color: $cerulean;
    border-radius: $nav-action-border-radius;
    color: $white;
    font-weight: $font-weight-semibold;
    margin-left: 7px;
    padding: 0 5px;

    @include media-breakpoint-up(sm) {
      margin-left: 5px;
    }
  }

  // stylelint-disable no-descending-specificity

  // styles for "tab" actions
  // nav-tabs are the actions on the left side of the navbar
  .navbar-tabs {
    // "selected" state for navbar-tabs
    a.active,   // format used for hiring
    .active > a,   // format used for scout-ui
    .active .btn {
      border-color: $skill-nav-item-border;
    }

    a,
    .dropdown-menu a {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;

      &:hover,
      &:active,
      &:focus {
        color: $skill-nav-link;
        background-color: transparent;
      }

      &:hover {
        border-color: $skill-nav-item-border;
      }

      &:active,
      &:focus {
        border-color: $skill-nav-item-border;
        outline: 0;
      }
    }

    .btn-dropdown {
      &:hover,
      &:active,
      &:focus {
        color: $skill-nav-link;
        border-color: $skill-nav-item-border;
      }
    }

    .dropdown-menu {
      @include skill-nav-dropdown-menu(sm);
    }
  }

  // behavior for "link" actions
  // nav-links are the actions on the right side of the navbar
  .navbar-links {
    // keeps navbar-links aligned to the right side of the nav
    // in cases where there are no navbar-tabs visible (like hiring in TPX)
    margin-left: auto;

    a,
    .btn-dropdown,
    .dropdown-menu a {
      font-weight: $font-weight-semibold;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active,
      &:active:hover {
        color: $skill-nav-link;
        background-color: transparent;
        border-color: transparent;
        text-decoration: underline;
        text-decoration-color: $crusta;
      }
    }

    // stylelint-enable no-descending-specificity

    .dropdown-menu {
      @include skill-nav-dropdown-menu($screen-links-collapse-min);
    }

    .dropdown {
      width: 100%;
    }
  }

  // styles that handle the UI when showing and hiding various sections
  .navbar-collapse {
    box-shadow: none;
    padding: 0;

    @include media-breakpoint-up(sm) {
      align-items: center;
      border: 0;
      margin: 0;

      // min-height: $navbar-content-height;
      width: 100%;

      &::after,
      &::before {
        display: none;
      }
    }

    // adjust transition speed and function of mobile nav expand/collapse
    .collapsing {
      transition-duration: $nav-mobile-duration-sm;
      transition-timing-function: $alert-ease-type;
    }

    @include media-breakpoint-between(sm, lg) {
      position: static;

      .navbar-links {
        background-color: $skill-nav-bg;
        display: none;
        position: absolute;
        right: 0;
        top: $navbar-height;
        height: auto;
        max-height: 0;
        padding: 0;
        transition: max-height $nav-mobile-duration-md ease;
        width: 22em;
      }
    }
  }
}
