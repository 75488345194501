// Copyright 2013-2023 Scout Exchange, LLC. All Rights Reserved.

// Dropdown
// --------------------------------------------------

// style changes to create a responsive dropdown
.dropdown-responsive {
  // restrict content to width of the block
  // create ellipsis at the end of block if text overflows the box
  // add padding to the right of box to account for caret icon
  .dropdown-content {
    @extend .ellipsis-block;

    padding-right: 20px;
  }

  // position caret icon absolutely so that it stays right-aligned
  .caret-wrap {
    position: absolute;
    top: 50%;
    margin-top: -9px;
    right: 10px;
  }
}

.dropdown-link {
  .caret {
    position: static;
  }
}

// create a "not selectable" state, which is intended to be different from
// "disabled" example of when this would be used is something like "--Please
// Select--"
.dropdown-menu {
  .dropdown-not-selectable {
    color: $inactive-text;
    display: block;
    padding: 3px 20px;
    pointer-events: none;
  }

  .btn-link {
    width: 100%;
    text-align: left;
    text-decoration: none;
    color: $body-color;
    padding: 3px 20px;

    &.text-danger {
      color: $brand-danger;
    }
  }
}

// add space between a "dropdown" and "typehead"
// stylelint-disable-next-line selector-class-pattern
.dropdown + .Select {
  margin-top: 2px;
}
