// coding:utf-8
// Copyright 2021 Scout Exchange, LLC. All Rights

@use "sass:color";

$selector-arrow-ltblue: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 4.2'%3E%3Cpath d='M7.5 0H.3C.1 0 0 .1 0 .3c0 .1 0 .2.1.2l3.6 3.6c.1.1.3.1.4 0L7.7.5c.1-.1.1-.3 0-.4 0-.1-.1-.1-.2-.1z' fill='%23006ca9'/%3E%3C/svg%3E";

// FIXME: switch to using the same <body> or <html>-level class for all
// skill.com pages
.skill-job-form-wrap,
.onboard-wrap {
  background-color: $white;
  color: $onboard-text;

  h1 {
    font-size: $skill-font-size-h1;
    font-weight: $font-weight-light;
    color: $onboard-page-headers;
    line-height: 1.2;
    margin-bottom: spacer(3);
  }

  h2 {
    font-size: $skill-font-size-h2;
    font-weight: $font-weight-light;
    color: $onboard-page-headers;
    line-height: 1.3;
    margin-top: spacer(4);
    margin-bottom: spacer(3);
  }

  h3 {
    font-size: $skill-font-size-h3;
    font-weight: $font-weight-semibold;
    color: $onboard-page-headers;
    line-height: 1.3;
    margin-top: spacer(3);
    margin-bottom: spacer(2);
  }

  h4 {
    font-size: $skill-font-size-h4;
    font-weight: $font-weight-bold;
    color: $onboard-page-headers;
    line-height: 1.4;
    margin-top: spacer(3);
    margin-bottom: spacer(2);
  }

  p {
    margin-bottom: spacer(2);
  }

  .control-label {
    font-weight: $font-weight-semibold;
  }

  .form-group {
    margin-bottom: spacer(2);
  }

  .form-control {
    border-color: $onboard-form-border;
    color: $onboard-form-color;

    &:disabled {
      opacity: 0.4;
    }
  }

  // reapply error styling to form-control to override border-color property
  // above
  .has-error .form-control {
    border-color: $brand-danger;
  }
}

.onboard-wrap {
  a,
  .btn-link {
    @extend %underline;

    color: $onboard-link-default;
  }

  .sub-blurb {
    @extend %text-large;

    margin-top: spacer(6);
  }

  // override width the Create Account page
  .skillcom-logo {
    width: 100%;
    max-width: 200px;

    // height needed for proper display/alignment in IE11
    max-height: 40px;
    min-width: 172px;
  }

  .btn-link.popover-trigger {
    border: 0;
    padding: 0;
  }
}

.onboard-frame {
  display: flex;

  // set value "column" here to align Footer
  flex-direction: column;
  width: 100%;
}

// wrapper for header and content
.onboard-content {
  border: spacer(2) solid $onboard-page-bg;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 88rem;
  width: auto;
}

@include media-breakpoint-up(md) {
  .onboard-content {
    flex-direction: row;
    margin-top: spacer(12);
    margin-bottom: auto;
    width: 88rem;
  }
}

.onboard-main {
  background-color: $white;
  padding: spacer(7);

  .checkbox {
    margin: spacer(6) 0;
  }
}

@include media-breakpoint-up(sm) {
  .onboard-main {
    padding: spacer(15);
  }
}

.text-large,
%text-large {
  font-size: 16px;
}

.paragraph-large {
  @extend %text-large;

  color: $onboard-page-paragraph;
}

.onboard-create-account {
  h2 {
    margin-top: 32px;
  }

  .checkbox {
    margin-top: 1.6rem;
  }
}

.paragraph-large-bottom-margin {
  font-size: 16px;
  color: $onboard-page-paragraph;
  padding-bottom: 25px;
}

.link-large-text {
  font-size: 16px;
}

// header
.onboard-header {
  background-color: $onboard-logo-background;
  color: $white;
  padding: spacer(7) spacer(18);
  text-align: center;

  img {
    width: 150px;
  }
}

@include media-breakpoint-up(md) {
  .onboard-header {
    max-width: 308px;
    padding: spacer(15) spacer(17);

    img {
      width: 100%;
    }
  }
}

.skill-page-border {
  border: solid 1px $activation-page-border;
  padding: 40px 63px 40px 64px;
}

.onboard-page-header {
  margin: 32px 183px 40px 0;
}

.checkbox-attached {
  margin-top: -20px;
}

// FIXME
.stripe-form {
  display: block;
  padding: 10px 14px;
  font-size: 1em;

  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  font-family: "Open Sans";
  border: 1px solid;
  outline: 0;
  background: $white;
}

.onboard-bottom-margin {
  margin-bottom: 15px;
}

.onboard-edit-button {
  margin: 0 0 2px 5px;
}

.onboard-accordion-icon {
  margin: 0 0 0 5px;
  color: $onboard-page-interactive;
}

.with-top-border {
  border-top: 1px solid $primary-variant-dark;
  padding-top: 0.5em;
}

// TODO: be in scout-ui?
.onboard-navbar-actions {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  text-align: left;
}

.onboard-navbar-actions:first-child {
  padding-top: 6px;
}

.onboard-navbar-actions:last-child {
  padding-bottom: 6px;
}

@media (min-width: 768px) {
  .onboard-navbar-actions {
    align-items: center;
    display: flex;
    text-align: left;
  }

  .onboard-navbar-actions > li {
    position: relative;
  }

  .onboard-navbar-actions:first-child {
    padding-top: 0;
  }

  .onboard-navbar-actions:last-child {
    padding-bottom: 0;
  }
}

.onboard-navbar-actions a {
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $onboard-page-navbar;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  padding: 12px;
  display: block;
}

@media (min-width: 768px) {
  .onboard-navbar-actions a {
    font-size: 12px;
    padding: 7px 7px 6px;
    white-space: nowrap;
  }
}

.onboard-navbar-actions .btn-dropdown {
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $onboard-page-navbar;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  float: none;
}

@media (min-width: 768px) {
  .onboard-navbar-actions .btn-dropdown {
    font-size: 12px;
    max-width: 125px;
    overflow: inherit;
    padding: 7px 7px 6px;
  }
}

.onboard-navbar-actions .btn-dropdown .caret {
  border-width: 0.34em;
  margin-top: 0;
  margin-left: 9px;
  position: static;
}

@media (min-width: 768px) {
  .onboard-navbar-actions .btn-dropdown .caret {
    margin-left: 7px;
  }
}

.onboard-navbar-actions li {
  padding: 3px 6px;
}

@media (min-width: 768px) {
  .onboard-navbar-actions li {
    padding: 0 5px;
  }
}

.onboard-navbar-actions .dropdown-menu li {
  padding: 3px 0;
}

.onboard-navbar-actions .dropdown-menu li:empty {
  padding: 0;
}

@media (min-width: 768px) {
  .onboard-navbar-actions .dropdown-menu li {
    padding: 3px 6px;
  }
}

.onboard-navbar-userinfo-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  .onboard-navbar-userinfo-wrap {
    align-items: center;
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .onboard-navbar-userinfo-wrap .dropdown {
    order: 1;
  }
}

.onboard-navbar-userinfo-wrap .popover-trigger {
  display: block;
  font-size: 12px;
  max-width: 8em;
  padding: 7px 7px 6px;
  text-align: right;
}

.onboard-navbar-userinfo-wrap .popover-trigger:hover {
  text-decoration: none;
}

@media (min-width: 1200px) {
  .onboard-navbar-userinfo-wrap .popover-trigger {
    margin-top: 0;
    max-width: none;
  }
}

.onboard-navbar-userinfo-wrap .rating-inline-text {
  color: #b3c6d3;
  font-size: 12px;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .onboard-navbar-userinfo-wrap .rating-inline-text {
    font-size: 11px;
  }
}

.onboard-navbar-links {
  margin-left: auto;
}

.onboard-navbar-links a,
.onboard-navbar-links .btn-dropdown,
.onboard-navbar-links .dropdown-menu a {
  text-decoration: none;
}

.onboard-navbar-links .dropdown-menu {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  float: none;
  padding: 3px 0;
  position: static;
  margin-top: 0;
  margin-left: 2em;
}

@media (min-width: 1200px) {
  .onboard-navbar-links .dropdown-menu {
    background-color: $white;
    border: 1px solid $onboard-page-navbar;
    margin-top: 9px;
    margin-left: 0;
    position: absolute;
  }
}

.onboard-navbar-links .dropdown-menu a {
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $onboard-page-navbar;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  padding: 12px;
  display: block;
}

// stylelint-disable no-descending-specificity

.onboard-navbar-links a:hover,
.onboard-navbar-links a:focus,
.onboard-navbar-links a:active,
.onboard-navbar-links a:active:hover,
.onboard-navbar-links .btn-dropdown:hover,
.onboard-navbar-links .btn-dropdown:focus,
.onboard-navbar-links .btn-dropdown:active,
.onboard-navbar-links .btn-dropdown:active:hover,
.onboard-navbar-links .dropdown-menu a:hover,
.onboard-navbar-links .dropdown-menu a:focus,
.onboard-navbar-links .dropdown-menu a:active,
.onboard-navbar-links .dropdown-menu a:active:hover {
  color: $onboard-page-navbar;
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  text-decoration-color: $onboard-page-navbar;
}

.onboard-navbar-links .dropdown-menu a:active,
.onboard-navbar-links .dropdown-menu a:focus {
  background-color: transparent;
  color: $onboard-page-navbar;
  outline: 0;
}

.onboard-navbar-links .dropdown-menu a:hover {
  background-color: transparent;
  color: $onboard-page-navbar;
}

// stylelint-enable no-descending-specificity

@media (min-width: 768px) {
  .onboard-navbar-links .dropdown-menu a {
    font-size: 12px;
    padding: 7px 7px 6px;
  }
}

.onboard-navbar-links .dropdown {
  width: 100%;
}

// Stepper styles

@mixin step-arrow($width, $color, $right, $top) {
  @include create-content(
    $right: $right,
    $top: $top
  );

  border-top: $width solid transparent;
  border-right: 0;
  border-bottom: $width solid transparent;
  border-left: $width solid $color;
}

@mixin filled-state($mainColor) {
  background-color: $mainColor;
  border-color: $mainColor;
  color: $white;

  &::after {
    @include step-arrow($width: 11px, $color: $mainColor, $right: -12px, $top: -1px);

    z-index: 2;
  }

  &::before {
    @include step-arrow($width: 14px, $color: $white, $right: -15px, $top: -4px);

    z-index: 1;
  }
}

.job-form-stepper-wrap {
  display: flex;

  // move the wrapper down 1 px to overlap the form's border and avoid the
  // "double border" look
  margin-bottom: -1px;
  width: 100%;
  z-index: 2;

  .job-form-stepper {
    display: flex;
    margin: 0 0 0 auto;
    padding: 0;
    list-style-type: none;
  }

  .job-form-step {
    background-color: $white;
    border: 1px solid $onboard-stepper-main-border;

    // use content-box so min-width doesn't include padding
    box-sizing: content-box;
    color: $onboard-stepper-main;
    font-weight: $font-weight-bold;
    line-height: 1;
    margin: 0;

    // min-width allows UI to show either text or a checkmark without changing
    // width
    min-width: 15px;
    padding: 0.3rem 0.8rem 0.3rem 1.8rem;
    position: relative;
    text-align: center;

    &::after {
      @include step-arrow(10px, $white, -10px, 0);

      z-index: 2;
    }

    &::before {
      @include step-arrow(11px, $onboard-stepper-main-border, -12px, -1px);

      z-index: 1;
    }

    &.step-complete {
      @include filled-state($onboard-stepper-complete);
    }

    &.step-active {
      @include filled-state($onboard-stepper-main);
    }

    &.step-complete,
    &.step-active {
      .svg-checkmark {
        // need to give height and width for IE11
        height: 10px;
        width: 11px;

        polyline {
          stroke: $white;
        }
      }
    }

    &:last-child {
      padding-right: 1.4rem;

      &::after,
      &::before {
        border: 0;
      }
    }
  }
}

.alert-hint {
  align-items: flex-start;
  background-color: $alert-hint-bg;
  display: flex;
  margin: spacer(4) 0;
  padding: spacer(3);

  .svg-lightbulb {
    height: 23px;
    margin-right: spacer(3);
    width: 15px;
  }
}

.skill-payment-summary {
  border: 1px solid $skill-payment-border;
  padding: 0 spacer(6) spacer(3);
  margin-top: spacer(4);

  // need "skill-payment-summary-header" nested in wrapper to help override
  // general h2 definitions
  .skill-payment-summary-header {
    font-size: $skill-font-size-h3;
    font-weight: $font-weight-semibold;
    margin: 0;
    padding: spacer(3) 0;
  }

  @include media-breakpoint-up(sm) {
    margin-top: 0;
  }
}

// need "skill-payment-step" wrapper to help override general h3 definitions
.skill-payment-step {
  .skill-payment-step-header {
    background-color: $skill-payment-step-header-bg;
    font-size: $skill-font-size-h3;
    font-weight: $font-weight-semibold;
    color: $white;
    margin: 0;
    padding: spacer(3) spacer(6);
  }

  .skill-payment-step-body {
    border: 1px solid $skill-payment-border;
    padding: spacer(6);
  }
}

.skill-purchase-summary {
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(sm) {
    border: 1px solid $skill-payment-border;
    flex-direction: row;
    width: auto;
  }

  @include media-breakpoint-up(md) {
    min-width: 50%;
  }
}

// stylelint-disable no-descending-specificity

.skill-static-address {
  p {
    margin-bottom: spacer(1);
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.skill-purchase-summary-block {
  padding: spacer(3) 0;

  @include media-breakpoint-up(sm) {
    padding: spacer(6);
  }

  .skill-purchase-summary-header {
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    margin-top: 0;
    margin-bottom: spacer(2);
  }

  p {
    line-height: 1.3;
    margin-bottom: spacer(2);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// stylelint-enable no-descending-specificity

.skill-purchase-summary-value {
  @include media-breakpoint-between(sm, md) {
    display: block;
  }
}

.skill-charges-list {
  width: max-content;

  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.skill-charge-item {
  display: flex;
  line-height: 1.3;
  margin-bottom: spacer(2);

  &:last-child {
    margin-bottom: 0;
  }
}

.skill-charge-description {
  margin-right: auto;
  padding-right: spacer(3);
}

.skill-charge-total {
  font-weight: $font-weight-bold;
}

.skill-page-sub-header {
  color: $onboard-page-headers;
  font-size: $skill-font-size-h2;
  font-weight: $font-weight-light;
}

.skill-purchase-sub-header {
  margin-top: spacer(3);
  margin-bottom: spacer(11);
}

// stylelint-disable no-descending-specificity

.skill-coupon-code-field {
  display: flex;
  margin-bottom: 5px;

  .form-control {
    width: 10rem;
  }

  .btn {
    margin-left: spacer(2);
  }
}

.onboard-code-verification-wrap {
  display: flex;
  align-items: flex-start;

  .form-group {
    margin-bottom: 0;

    > div {
      width: 15rem;
    }
  }

  .form-control {
    margin-right: spacer(2);
    width: 100%;
  }

  .onboard-code-new-code {
    margin: spacer(8) 0 0 spacer(2);
  }
}

.onboard-email-verification-modal {
  .onboard-code-countdown {
    font-weight: $font-weight-semibold;
    color: $scout-status;
  }

  .form-control {
    width: spacer(20);
  }
}

.skill-job-form-wrap {
  h2 {
    // remove top margin for <h2> at top of a section
    &:first-of-type {
      margin-top: 0;
    }
  }

  .form-group {
    margin-bottom: spacer(4);
  }

  .control-label {
    margin-bottom: spacer(0.5);
  }

  .field-description {
    margin-top: -(spacer(0.5));
    margin-bottom: spacer(1);
  }

  .skill-page-border {
    border: solid 1px $activation-page-border;
    padding: spacer(10);
  }

  .form-additional-group {
    margin-left: spacer(5);

    .control-label {
      font-weight: $font-weight-semibold;
      padding-left: 0;
    }
  }

  .skill-placement-row {
    display: flex;

    > .relative,
    > .form-group {
      width: 130px;
    }

    > .field-description {
      font-size: $font-size-sm;
      margin-left: spacer(2);
      margin-top: 0;
      width: 200px;
    }
  }

  // '.radio' has bottom-margin. Use negative margin to override that margin
  // and pull "form-additional-group" up closer to radio
  .radio + .form-additional-group {
    margin-top: -(spacer(1));
  }

  .input-group-addon {
    background-color: transparent;
    color: $onboard-text;
    padding: 6px 8px;
  }

  // reapply error styling to input-group-addon to override color property above
  .has-error .input-group-addon {
    background-color: $brand-danger;
    color: $white;
  }

  select.form-control {
    // replace arrow in <select> dropdown with gray version of inline svg arrow
    background-image: url($selector-arrow-ltblue);
  }

  .skill-pre-screen-wrap {
    // mimic spacing of "form-group"
    margin-bottom: spacer(4);

    .form-group {
      margin-bottom: 0;
    }

    .help-block {
      margin-bottom: 0;
    }

    .checkbox {
      margin-top: spacer(1);
    }
  }

  .skill-pre-screen-questions-list {
    counter-reset: prescreen;
    font-weight: $font-weight-bold;
    margin-bottom: spacer(5);
    padding: 0;

    &:empty {
      margin-bottom: 0;
    }

    .skill-pre-screen-question {
      display: flex;
      list-style: none;
      padding-left: spacer(4);
      position: relative;

      &::before {
        content: counter(prescreen) ") ";
        counter-increment: prescreen;
        left: 0;
        position: absolute;
      }

      + .skill-pre-screen-question {
        margin-top: spacer(1);
      }
    }

    .skill-pre-screen-delete {
      @extend %underline;

      font-weight: $font-weight-normal;
      cursor: pointer;
      margin-left: auto;
    }
  }

  // ======== styles for HTML/WYSIWYG input field
  // class names are from the package
  // stylelint-disable selector-class-pattern

  .RichTextEditor__root___2QXK- {
    // hide the border that surrounds the component
    border-color: transparent;
    border-radius: 0;
  }

  .EditorToolbar__root___3_Aqz {
    // apply the border to the editor section but remove the bottom line
    border: 1px solid $onboard-form-border;
    border-bottom-width: 0;
    padding: 10px 10px 5px;
    margin: 0;
  }

  .RichTextEditor__editor___1QqIU {
    // apply border around entire editor section so that we can change the color
    // to red for validation
    border: 1px solid $onboard-form-border;
  }

  .ButtonGroup__root___3lEAn {
    padding: 0 spacer(4);
    margin: 0;

    &:first-of-type {
      padding-left: 0;
    }

    + .ButtonGroup__root___3lEAn {
      border-left: 1px solid $primary-variant-dark;
    }
  }

  .Button__root___1gz0c {
    background: transparent;
    border-color: transparent;

    &.IconButton__isActive___2Ey8p,
    &:hover:not(:disabled) {
      outline: 1px solid $primary-variant-dark;
      z-index: 11;
    }
  }

  .form-group.has-error {
    .RichTextEditor__editor___1QqIU {
      // change the border to red if there's an error
      border-color: $brand-danger;
    }
  }

  // ======== END styles for HTML/WYSIWYG input field

  // ======== styles for Locations autocomplete field
  .Select-control,
  .Select-option:not(.is-focused) {
    color: $onboard-text;
  }

  .Select-control,
  .Select.is-open .Select-control,
  .Select-menu-outer {
    border-color: $onboard-form-border;
  }

  .Select-arrow,
  .Select-arrow:hover {
    border-top-color: $onboard-link-default;
  }

  .Select.is-open .Select-arrow,
  .Select.is-open .Select-arrow:hover {
    border-bottom-color: $onboard-link-default;
  }

  .Select-placeholder,
  .Select-noresults {
    color: color.adjust($onboard-text, $lightness: 40%);
  }

  // stylelint-enable selector-class-pattern

  .job-locations-container {
    small {
      font-size: $font-size-base;
    }

    // add error styling since the component's <label> doesn't have the
    // "control-label" class
    &.has-error {
      > label {
        color: $brand-danger;
      }
    }
  }

  .job-locations-list {
    display: flex;
    flex-wrap: wrap;
  }

  .job-location-list-item {
    align-items: center;
    background-color: $primary-variant-lighter;
    border-radius: 40px;

    // 'border' added to match sizing of "Select-value" component from
    // "react-select"
    border: 1px solid transparent;
    display: flex;
    font-size: $font-size-sm;

    // there's more padding on the left to offset the "m-r-xs" that's on the
    // svg-btn on the component
    padding: spacer(0.5) spacer(1) spacer(0.5) spacer(3);
    margin-right: spacer(2);

    .svg-btn {
      order: 1;
      margin: 0 0 0 spacer(2);

      line {
        stroke: $dusty-gray;

        // this 'stroke-width' is intended to match the look of the react-select
        // 'Select-value-icon' which has a font-weight of 300
        stroke-width: 0.75;
      }

      .svg-badge {
        border: 0;
        height: 18px;
        width: 18px;
      }

      &:hover {
        .svg-badge {
          background-color: transparent;

          line {
            stroke: $select-item-hover-color;
          }
        }
      }
    }
  }

  .job-locations-error {
    // should be equal to the negative value of `m-b-md`
    // which is on the Locations component
    margin-top: -15px;
  }

  // ======== END styles for Locations autocomplete field

  // ======== styles for Soft Skills multi-select
  .soft-skill-other-wrap {
    display: flex;
    align-items: flex-start;
    margin-top: spacer(1);

    .soft-skip-other-field-wrap {
      flex-grow: 1;
    }

    &.has-error .form-group {
      margin-bottom: 0;
    }

    .btn {
      margin-top: 25px;
      margin-left: spacer(2);
    }
  }

  // ======== END styles for Soft Skills multi-select

  // the "modal-title" in the component is an <h4>
  // these styles duplicate scout-ui styles in order to override ".onboard-wrap
  // h4" styles
  .modal-title {
    font-size: 2rem;
    font-weight: $font-weight-normal;
    line-height: 1.4;
    margin: 0;
  }

  // alert style for Create Account page and verification code modal
  .alert {
    display: flex;
    align-items: flex-start;

    .alert-content {
      padding-left: 12px;
    }
  }
}

// stylelint-enable no-descending-specificity
