// coding:utf-8
// Copyright 2021 Scout Exchange, LLC. All Rights

// Onboard Pages

// ##

// === new colors
// headers
$congress-blue: #003e7e;

// links
$allports: #006ca9;

// buttons
$lochmara: #0077c8;

// text color
$mine-shaft: #3d3d3d;
$loblolly: #bfc8ce;
$alice-blue: #f0f9ff;
$black-squeeze: #f4f8fb;

// font sizes
$skill-font-size-h1: 3.6rem;
$skill-font-size-h2: 2.4rem;
$skill-font-size-h3: 1.8rem;
$skill-font-size-h4: 1.4rem;

// success-type colors
$christi: #6da314;
$tango: #eb6b1b;
$atomic-tangerine: #ffa66f;

// onboard variables
$onboard-default: $mine-shaft;
$onboard-link-default: $allports;
$onboard-text: $onboard-default;
$onboard-form-border: $onboard-default;
$onboard-form-color: $onboard-default;
$onboard-btn-bg: $lochmara;
$onboard-btn-border: $lochmara;
$onboard-page-bg: $cerulean;
$activation-page-border: $loblolly;
$onboard-page-headers: $congress-blue;
$onboard-page-navbar: #002565;
$onboard-page-paragraph: #333;
$onboard-logo-background: #eaeaea;
$onboard-page-interactive: $cerulean;
$onboard-stripe-shadow: rgb(50 50 93 / 14.9%);
$onboard-stripe-shadow2: rgb(136 121 121 / 2%);
$onboard-stepper-main-border: $loblolly;
$onboard-stepper-main: $allports;
$onboard-stepper-complete: $christi;
$onboard-icon: $allports;
$alert-hint-bg: $alice-blue;
$alert-hint-icon: $scout-status;
$alert-hint-icon-faint: $atomic-tangerine;
$skill-nav-bg: $gallery;
$skill-nav-link: $onboard-link-default;
$skill-nav-item-border: $tango;
$skill-nav-bottom-border: $curious-blue;
$skill-svg-stroke: $allports;
$skill-payment-border: $loblolly;
$skill-payment-step-header-bg: $congress-blue;
