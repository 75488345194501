// coding:utf-8
// Copyright 2013-2017 Scout Exchange, LLC. All Rights

// Styles for the Icon Guide component
// -------------------------
// The icon guide shows a list of the icons that we use
// in the app along with a description of what they mean

.icon-guide {
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  position: relative;
  z-index: 2;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 7px;
  }
}

.icon-guide-item {
  align-items: flex-start;
  display: flex;
  padding: 5px 0;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.icon-guide-img {
  height: 23px;
  margin: 0 5px 0 0;
}

.icon-guide-header {
  font-size: 12px;
  margin-top: 3px;
}
