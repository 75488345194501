// Copyright 2016-2023 Scout Exchange, LLC. All Rights Reserved.
// Tooltip/Popover styles

.popover {
  background-color: $scout-primary;
  color: $white;
  font-size: 12px;
  font-style: italic;
  left: 200px;
  border: 0;

  &.top > .arrow,
  &.bottom > .arrow {
    &::after {
      margin-left: -$popover-arrow-width;
      border-width: 0;
      border-color: transparent;
    }
  }

  a {
    color: $white;
  }

  .close {
    color: $white;
    font-weight: 400;
    font-style: normal;
    font-size: 21px;
    text-shadow: none;
    opacity: 1;
    padding: 4px;
    position: relative;
    right: -20px;
    top: -4px;
  }

  // position an element in the lower right-hand corner of the popover
  .absolute-bottom-right {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &.top > .arrow {
    left: $popover-arrow-width - 1;
    margin-left: 0;
    border-width: $popover-arrow-width $popover-arrow-width 0 0;
    border-color: $popover-arrow-outer-color transparent transparent transparent;
    bottom: -$popover-arrow-width + 2;
  }

  &.bottom > .arrow {
    left: $popover-arrow-width - 1;
    margin-left: 0;
    border-width: $popover-arrow-width 0 0 $popover-arrow-width;
    border-color: transparent transparent transparent $popover-arrow-outer-color;
    top: -$popover-arrow-width + 2;
  }
}

// share history popover; different colors (TODO), text style and max size

.popover-trigger {
  cursor: pointer;

  // styles when nested within 'stack-detail' or 'stack-detail-sm'
  [class^="stack-detail"] & {
    @extend .underline;

    margin-left: 5px;
  }
}

.popover-light {
  @extend .popover;

  background-color: $white;
  border: 1px solid $scout-primary;
  color: $scout-primary;
  font-style: normal;
  max-width: 500px;

  &.top > .arrow {
    border-width: 18px 18px 0 0;

    &::after {
      margin: 1px;
      border-width: 17px 17px 0 0;
      border-color: $white transparent transparent transparent;
    }
  }

  &.bottom > .arrow {
    left: $popover-arrow-width - 1;
    margin-left: 0;
    border-width: 18px 0 0 18px;
    border-color: transparent transparent transparent $popover-arrow-outer-color;
    top: -18px;

    &::after {
      margin: -17px;
      border-width: 16px 0 0 16px;
      border-color: transparent transparent transparent $white;
    }
  }

  a {
    color: $scout-action;
  }

  .spaces {
    white-space: pre;
  }

  .emails {
    font-weight: $font-weight-bold;
  }

  .close {
    color: $scout-primary;
    font-weight: 400;
    font-style: normal;
    font-size: 21px;
    text-shadow: none;
    opacity: 1;
    padding: 4px;
    position: absolute;
    top: 3px;
    right: 6px;
  }

  &.right {
    margin-left: 23px;
  }

  &.right > .arrow {
    border-color: $scout-primary $scout-primary transparent transparent;
    border-width: 10px;
    margin-top: 0;

    &::after {
      border-color: $white $white transparent transparent;
      border-width: 10px;
      left: -8px;
      bottom: -11px;
    }
  }
}

.popover-content-light {
  max-width: 450px;
  max-height: 200px;
  overflow-y: auto;
}

.recruiter-name-rating-wrpr {
  display: flex;
  align-items: center;

  .recruiter-rating-item {
    margin-right: 5px;

    .glyphicon {
      font-size: 16px;
    }
  }
}

.star-rating-popover-details {
  font-size: $font-size-base;

  // Adding this here to override values for `h2` coming from scout-ui
  h2 {
    font-size: $h2-font-size;
    font-weight: $headings-font-weight;
  }

  .h5 {
    font-size: 14px;
    font-weight: 600;
  }

  .no-rating {
    font-weight: 600;
  }

  .rating-item {
    margin-bottom: 4px;
  }

  .rating-inline-text {
    color: inherit;
  }

  .rating-notes {
    font-size: 11px;
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 2px 4px;
  }
}

.stack-detail {
  .rating-inline {
    // override 'm-l-xs' on parent
    margin-left: -5px;
  }
}

.rating-user {
  font-size: 16px;
  font-weight: 600;
}

.pause-popover-details {
  font-style: normal;
}

.default-info {
  padding-left: 15px;
}

.label-with-info {
  .control-label {
    display: inline;
    padding-right: spacer(2);
  }
}

.more-info-popover {
  .popover-trigger {
    cursor: auto;
    position: relative;
  }
}
