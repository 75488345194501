// Copyright 2013-2020 Scout Exchange, LLC. All Rights Reserved.

// ================================
// Additional TPX-specific Nav styles to display the user rating
// these can probably be removed when the user rating has been added to the
// scout-ui Nav component
// ================================

.navbar {
  // "svg-external-link" is used by Skill.com
  .svg-external-link {
    // use 'content-box so that padding is calculated as part of the width
    box-sizing: content-box;
    height: 14px;
    padding-left: spacer(1);
    width: 14px;

    path {
      stroke: $white;
      stroke-width: 1;
    }
  }

  @include media-breakpoint-up(sm) {
    .svg-external-link {
      height: 11px;
      padding-left: spacer(1);
      width: 11px;
    }
  }
}

// "envelope" icon button in header
.navbar-messages {
  position: relative;

  .badge {
    position: absolute;
    top: px-to-rem(4);
    left: px-to-rem(25);
    z-index: 10;

    @include media-breakpoint-up(md) {
      top: px-to-rem(3);
      left: px-to-rem(25);
    }
  }

  .btn-dropdown {
    line-height: 1;
    padding-top: px-to-rem(11);
    padding-bottom: px-to-rem(11);
  }

  .has-messages {
    .btn-dropdown {
      &::after {
        margin-left: px-to-rem(16);
      }
    }
  }

  .dropdown-menu {
    max-width: 40em;

    // set dropdown-menu to open to the left
    left: auto;
    right: 0;

    a {
      white-space: normal;
    }

    .dropdown-header {
      @include navItemFontSize;

      white-space: normal;
    }

    @include media-breakpoint-up(md) {
      // set dropdown-menu to open to the right
      left: 0;
      right: auto;
    }
  }

  @include media-breakpoint-up(sm) {
    .dropdown-menu {
      min-width: 29em;
    }
  }
}
