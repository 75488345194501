// Modals
// --------------------------------------------------

// customized styles to override Bootstrap default modals styles
// using their exact syntax

.modal-footer {
  // align text left so that buttons align left as well (BS default is
  // 'text-right')
  text-align: left;
}

.modal-warning-wrap .alert,
.modal-error-wrap .alert {
  margin-bottom: 0;
}

.modal-warning-text {
  color: $brand-warning;
  font-weight: $font-weight-bold;
}

.modal {
  .fixed-alert {
    position: absolute;
  }

  .modal-section + .modal-section {
    @extend .m-t-xl;
  }
}

@include media-breakpoint-up(sm) {
  .modal-lg {
    width: $modal-lg;
    max-width: $modal-lg-max;
  }
}
