// Copyright 2013-2020 Scout Exchange, LLC. All Rights Reserved.

// Buttons
// --------------------------------------------------

.btn-xs {
  padding: 1px 5px;
}

// button that shows animated "progress" at top of the button

@keyframes diag {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 40px 10px;
  }
}

.btn-loader {
  position: relative;

  &::after {
    @include create-content($top: 0, $left: 0, $width: 100%, $height: 0);

    background-image: linear-gradient(45deg, $scout-action 25%, $white 25%, $white 50%, $scout-action 50%, $scout-action 75%, $white 75%, $white);
    background-size: 10px 10px;
    transition: height 0.25s;
    animation: diag 1s linear infinite;
  }

  &.is-loading {
    &::after {
      height: 15%;
    }
  }
}

.btn-create-req {
  margin: 5px 10px 5px 0;
}

.btn-envelope {
  background: transparent;
  line-height: 1;
  padding: 0;
  width: auto;

  .svg-envelope {
    height: px-to-rem(17);

    path {
      stroke: $white;
    }
  }

  &.has-messages {
    .svg-envelope {
      path {
        stroke: $scout-action;
      }
    }
  }

  &:hover {
    .svg-envelope {
      path {
        stroke: $scout-action;
      }

      &-body,
      &-top {
        fill: $foam;
      }
    }
  }
}

// recreate "btn-block" but from a parent level
.btn-full {
  .btn {
    display: block;
    width: 100%;
  }
}
