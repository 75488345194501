// coding:utf-8
// Copyright 2013-2023 Scout Exchange, LLC. All Rights

.skip-to-content {
  position: absolute;

  // background-color: $navbar-inverse-bg;
  border-color: $white;
  border-width: 0 0 3px;
  color: $white;
  display: block;
  font-size: $font-size-lg;
  padding: spacer(2) spacer(4);
  text-align: center;
  top: -3000px;
  width: 100%;
  z-index: -1;

  &:active,
  &:focus {
    position: static;
    top: 0;
    z-index: 1000;
  }
}
