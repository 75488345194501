// coding:utf-8
// Copyright 2013-2017 Scout Exchange, LLC. All Rights

@mixin grow-up {
  position: relative;
  background: transparent;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: height 0.25s ease-out, background-color 0.25s;
    z-index: -1;
  }
}

@mixin grow-up-background(
  $startBackColor: #ccc,
  $startHeight: 3px,
  $endBackColor: #ccc,
  $endHeight: 100%
) {
  @include grow-up;

  &::after {
    height: $startHeight;
    background-color: $startBackColor;
  }

  &:hover {
    &::after {
      background-color: $endBackColor;
    }
  }

  &.active {
    &::after {
      height: $endHeight;
      background-color: $endBackColor;
    }
  }
}

@mixin grow-up-border(
  $startBordColor: #ccc,
  $startHeight: 2px,
  $bordWidth: 1px,
  $endBordColor: #ccc
) {
  @include grow-up;

  &::after {
    height: $startHeight;
    border: $bordWidth solid $startBordColor;
  }

  &:hover {
    &::after {
      border-color: $endBordColor;
    }
  }

  &.active {
    &::after {
      height: 100%;
      border-color: $endBordColor;
    }
  }
}

@mixin create-content(
  $content: "",
  $top: auto,
  $right: auto,
  $bottom: auto,
  $left: auto,
  $width: auto,
  $height: auto
) {
  position: absolute;
  content: $content;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
}

@mixin scout-icon-base(
  $float: left,
  $margin: 0,
  $padding: 0,
  $height: 15px,
  $width: 15px,
  $backPosition: 0 0,
  $backRepeat: no-repeat
) {
  float: $float;
  margin: $margin;
  padding: $padding;
  height: $height;
  width: $width;
  background-position: $backPosition;
  background-repeat: $backRepeat;
}

@mixin badge-icon-with-hover(
  $leftStatic: -1px,
  $leftHover: -41px,
  $top: -1px
) {
  background-position: $leftStatic $top;

  &:hover,
  &:focus,
  &.focus {
    background-position: $leftStatic $top;
  }

  &:active,
  &.active {
    background-position: $leftHover $top;
  }

  .inactive &,
  &.disabled,
  &[disabled] {
    background-position: $leftHover $top;
  }
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin circle-border(
  $color: $scout-action,
  $radius: 50%,
  $style: solid,
  $width: 1px
) {
  border-color: $color;
  border-radius: $radius;
  border-style: $style;
  border-width: $width;
}

@mixin force-text-wrap {
  // "overflow-" is the standard
  // but not all browsers support it yet
  // so we used various properties below
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

@mixin label-disabled {
  color: $primary-variant-darker;
  cursor: not-allowed;
}

// ===== from scout-ui

@mixin nav-dropdown-menu($min-width-breakpoint) {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  float: none;
  padding: 3px 0;

  // keep "position" value set to something other than "static"
  // or else the dropdown menu items won't capture the click on mobile devices
  position: relative;
  margin-top: 0;
  margin-left: 2em;
  top: 0;

  @media (min-width: $min-width-breakpoint) {
    // background-color: $navbar-inverse-bg;
    border: 1px solid $navbar-dropdown-menu-border;
    margin-top: $navbar-dropdown-margin-top;
    margin-left: 0;
    position: absolute;
    top: 100%;
  }

  a {
    @include navActionBasics;

    display: block;

    &:hover {
      background-color: transparent;

      // color: $navbar-inverse-link-color;
    }

    &:active,
    &:focus {
      background-color: transparent;

      // color: $navbar-inverse-link-color;
      outline: 0;
    }
  }
}

@mixin skill-nav-action-basics {
  @include navItemFontSize;

  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  color: $skill-nav-link;
  cursor: pointer;
  width: 100%;
  padding: 12px;

  @include media-breakpoint-up(sm) {
    padding: spacer(1) spacer(2);
  }
}

@mixin skill-nav-dropdown-menu($min-width-breakpoint) {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  float: none;
  padding: 3px 0;

  // keep "position" value set to something other than "static"
  // or else the dropdown menu items won't capture the click on mobile devices
  position: relative;
  margin-top: 0;
  margin-left: 2em;
  top: 0;

  @media (min-width: $min-width-breakpoint) {
    background-color: $skill-nav-bg;
    border: 1px solid $curious-blue;
    margin-top: $navbar-dropdown-margin-top;
    margin-left: 0;
    position: absolute;
    top: 100%;
  }

  a {
    @include skill-nav-action-basics;

    display: block;

    &:hover {
      background-color: transparent;
      color: $skill-nav-link;
    }

    &:active,
    &:focus {
      background-color: transparent;
      color: $skill-nav-link;
      outline: 0;
    }
  }
}
