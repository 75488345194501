// coding:utf-8
// Copyright 2013-2020 Scout Exchange, LLC. All Rights

// Structure
// styles for page layout
// currently used for new Flexbox-based styles

.l-flex {
  display: flex;
  flex-direction: column;
}

.l-side {
  background-color: $primary-variant-lighter;

  .l-side-section {
    padding: 1em;

    + .l-side-section {
      border-top: 1px solid $primary-variant-light;
    }
  }

  .dropdown {
    width: 100%;

    .btn-dropdown {
      width: 100%;
    }
  }

  .btn {
    @extend .btn-sm;
  }

  .form-control {
    @extend .input-sm !optional;
  }
}

.l-main {
  @include media-breakpoint-up(sm) {
    flex: 1;
  }
}

@include media-breakpoint-up(md) {
  .l-flex {
    flex-direction: row;
  }

  .l-side {
    flex: 0 0 $sidebar-flex-basis;

    .l-side-section {
      padding: 1em 2em;
    }

    &.l-side-left {
      box-shadow: inset -6px 0 6px -6px $box-shadow;
    }

    &.l-side-right {
      box-shadow: inset 6px 0 6px -6px $box-shadow;
    }
  }

  .l-main {
    min-height: calc(100vh - #{$navbar-height} - #{$navbar-border-height});

    .container-fluid {
      padding: 0 $main-section-padding;
    }
  }
}

@include media-breakpoint-up(sm) {
  .flex-inline-space-between {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

//  adjustments for when the two-column layout is within a tab
.tab-pane {
  .l-main {
    padding: 0 2em;
  }

  @include media-breakpoint-up(sm) {
    .l-main {
      min-height: auto;
      padding: 0 3em;
    }
  }
}

.prefs-saved-sets-list {
  column-count: 3;
  column-gap: 32px;
  column-rule: 1px solid $warm-neutral-8;
}
