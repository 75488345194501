// coding:utf-8
// Copyright 2013-2018 Scout Exchange, LLC. All Rights

.candidate-review-job-card {
  border-top: 1px solid $primary-variant-dark;
  margin-bottom: 40px;
  padding-top: 20px;

  &:first-of-type {
    border-top: 0;
  }
}

.candidate-review-job-details {
  margin-bottom: 10px;

  h4 {
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 5px;
  }
}
