.btn-row {
  margin-top: 20px;
  padding-top: 20px;

  .btn + .btn {
    margin-left: 10px;
  }
}

.btn-row-border-top {
  border-top: 1px solid $primary-variant-light;
}

.btn-row-neg-margin {
  @include media-breakpoint-up(sm) {
    margin-left: -1em;
    margin-right: -1em;
  }
}

.btn-row-space-between {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
  }

  label {
    margin-top: -5px;
    align-self: center;
    font-size: 12px;
    font-weight: 600;
  }
}

.btn-list-row {
  display: flex;
  flex-wrap: wrap;
  grid-gap: spacer(1.5);
}
