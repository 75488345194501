// Copyright 2020 Scout Exchange, LLC. All Rights
$svg-path: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 4.2'%3E%3Cpath d='M7.5 0H.3C.1 0 0 .1 0 .3c0 .1 0 .2.1.2l3.6 3.6c.1.1.3.1.4 0L7.7.5c.1-.1.1-.3 0-.4 0-.1-.1-.1-.2-.1z' fill='%23255b80'/%3E%3C/svg%3E";

// this technique for creating a <select> arrow was described here:
// https://www.filamentgroup.com/lab/select-css.html

@supports ((-webkit-appearance: none) or (-moz-appearance: none) or (appearance: none)) {
  // if a browser supports the 'appearance: none' property (or -webkit/-moz
  // variation), it will process the styles underneath, which will hide the
  // default <select> arrow and replace it
  select.form-control {
    // hide the default arrow using "appearance: none"
    appearance: none;

    // add the SVG arrow as a background image
    background-image: url($svg-path);
    background-repeat: no-repeat;

    // position the SVG arrow to the right and center it vertically
    background-position: right 0.6em top 50%;
    background-size: 0.5em auto;
    padding-right: 26px;
    position: relative;
    width: fit-content;

    &:disabled {
      // copied from Scout UI styles for disabled <select>
      border-color: $primary-variant-dark;
      background-color: $input-disabled-bg;
      color: $input-color-disabled;
    }
  }
}
