// coding:utf-8
// Copyright 2013-2020 Scout Exchange, LLC. All Rights

// Sidebar
// styles for alignment and text styles in the sidebar
// NOTE: note intended for general structure (see: structure.scss)

.sidebar-title,
.sidebar-label {
  font-size: 12px;
  font-weight: 600;
}

.sidebar-label {
  display: flex;
  gap: px-to-rem(4);
  align-items: center;

  .more-info-popover {
    margin-top: px-to-rem(-2);
  }
}

.sidebar-action {
  flex-grow: 1;
  font-size: 12px;
  font-weight: $font-weight-normal;
  margin-top: 8px;
  padding-bottom: 0.5em;
  text-align: right;
  cursor: pointer;
}

.sidebar-title {
  flex-grow: 3;
  margin-top: spacer(2.5);
  margin-bottom: spacer(2.5);
}

.sidebar-head {
  display: flex;
  border-bottom: 1px solid $primary-variant-darker;
  margin-bottom: 0.5em;
}

.sidebar-group {
  + .sidebar-group {
    margin-top: 30px;
  }

  > .form-group {
    margin-bottom: 12px;
  }

  .radio-group {
    .radio {
      margin-bottom: spacer(2);
    }
  }
}

.checkbox-stack {
  .checkbox {
    margin: 0;
  }
}

.sidebar-radio-wrap,
.sidebar-checkbox-wrap {
  font-size: $font-size-sm;

  .sidebar-label {
    margin-top: spacer(2);
    margin-bottom: spacer(0.5);
  }
}

.filter-checkbox-content {
  align-items: flex-start;
  display: flex;
  margin-top: spacer(0.5);

  .svg {
    margin-right: spacer(1.5);
  }

  .svg-paw-print {
    height: spacer(4.5);
    width: spacer(4.5);

    // padding-bottom makes spacing between checkboxes equal
    // offsets the height difference between this icon and the other two
    padding-bottom: spacer(0.75);
  }

  .svg-diversity,
  .svg-preferred {
    height: spacer(4.5);
    width: spacer(4.5);
  }
}

.filter-tooltip {
  .svg-paw-print {
    height: px-to-rem(13);
    width: px-to-rem(13);
    margin-top: px-to-rem(-2);
    margin-left: px-to-rem(2);
  }

  .svg-diversity,
  .svg-preferred {
    height: px-to-rem(16);
    width: px-to-rem(16);
    margin-bottom: px-to-rem(2);
  }
}
