// coding:utf-8
// Copyright 2013-2017 Scout Exchange, LLC. All Rights

// custom styles for rich text editor (or WYSIWYG editor)
// ------------------------------------------------------
// We use the 'react-rte' npm library to create our WYSIWYG rich text editor.

// They add their styles directly onto the page, which makes it harder to
// override them from the stylesheet.

// The styles below are intended to override their styles.

// set a height for the text editor portion of the component
// stylelint-disable-next-line selector-class-pattern
.public-DraftEditor-content {
  min-height: 300px;
}

// stylelint-disable-next-line selector-class-pattern
.announcement-field .public-DraftEditor-content {
  min-height: 100px;
}

// override 'react-rte' styles to use Scout styles
// this will find the class that begins with 'RichTextEditor__root'
div[class^="RichTextEditor__root"] {
  font-family: inherit;
}

// override 'react-rte' styles to use Scout base font-size
// this will find the class that begins with 'RichTextEditor__editor'
div[class^="RichTextEditor__editor"] {
  font-size: inherit;
}
