// coding:utf-8
// Copyright 2013-2022 Scout Exchange, LLC. All Rights


// Scout Variables
// Should be loaded before the main Bootstrap styles

// --------------------------------------------------


// == Colors

// ## Where do the names come from?

// Arbitrary color names chosen intentionally using The Crayola Method:
// https://coryetzkorn.com/blog/color-variables-in-sass/

// Color variable names generated from:
// http://chir.ag/projects/name-that-color

// ## Naming Convention

// In multi-word names, separate all words with a hyphen:
// ex. $wild-sand

// For duplicate names, use sass color functions to help differentiate. Include
// the function and value in the name, separated from color with dash:
// ex: $silver-darken9

@use "sass:color";
@use "sass:map";

// TPX-specific
$silver-darken9: color.adjust($silver, $lightness: -9%);
$foam: #e6f6fd;
$no-ratings-text: $primary-variant-dark;


// == Scaffolding

// ##
$body-background: $white;
$onahau: #c7e6ff;
$dove-gray: #636363;

// == Ratings

// ##

// star rating variants
$star-hiring: $scout-primary;
$star-staffing: $scout-action;
$star-poor: $brand-danger;
$star-fair: $brand-warning;
$star-good: $brand-success;


// == Icons

// ## Settings icons.
$svg-icon-sm-size: 24px;
$svg-icon-sm-padding: 5px;
$svg-icon-lg-size: 50px;
$svg-icon-xs-size: 16px;
$svg-bg: transparent;
$svg-border: $scout-primary;
$svg-fill: transparent;
$svg-stroke: $scout-primary;
$svg-non-link-bg: $scout-primary;
$svg-hover-bg: $foam;
$svg-hover-border: $scout-action;
$svg-hover-fill: $white;
$svg-hover-stroke: $scout-action;
$svg-active-bg: $scout-primary;
$svg-active-border: $scout-primary;
$svg-active-fill: $scout-primary;
$svg-active-stroke: $white;
$svg-disabled-bg: $wild-sand;
$svg-disabled-border: $silver-darken9;
$svg-disabled-color: $silver-darken9;
$svg-disabled-fill: $wild-sand;
$svg-disabled-stroke: $silver-darken9;
$svg-positive: $brand-success;
$svg-negative: $brand-danger;

// ## Spike Badge
$spike-badge-size: 23px;
$spike-badge-icon-size: 11px;
$spike-badge-icon-position: calc((#{$spike-badge-size} - #{$spike-badge-icon-size}) / 2);


// == Badges

// ##

$badge-bg: $scout-status;
$badge-inactive-bg: $primary-variant-lighter;
$badge-inactive-color: $scout-primary;


// == Dropdowns

// ## Dropdown menu container and contents.

$dropdown-header-color: $scout-primary;
$dropdown-header-font-weight: $font-weight-semibold;


// == Page title container sizes

// ## These wrap the page title and back button.
// ## They're based on and wider than the .container sizes.

// Width increase
$title-container-increase: 6em;

// Small
$title-container-sm: map.get($container-max-widths, "sm") + $title-container-increase;

// Medium
$title-container-md: map.get($container-max-widths, "md") + $title-container-increase;

// Large
$title-container-lg: map.get($container-max-widths, "lg") + $title-container-increase;
$title-container-xl: map.get($container-max-widths, "xl") + $title-container-increase;


// == Navs

// ##

$nav-disabled-link-border-color: $primary-variant-dark;


// == Form states and alerts

// ## Define colors for form feedback states and, by default, alerts.

$state-info-text: $brand-info !default;
$state-info-bg: $brand-info !default;
$state-info-border: $brand-info !default;


// == Alerts

// ## Define alert colors, border radius, and padding.


// override scout-ui value, which uses the cerulean color
$alert-info-bg: $scout-primary !default;
$alert-info-text: $state-alert-text !default;
$alert-info-border: $scout-primary !default;


// == Columns

// ##

$default-padding: 1em;
$main-section-padding: 4em;
$main-section-info-padding: 3em;


// == Loaders

// ##
$loader-sphere-size: 10px;


// == Copy Job

// ##
$copy-job-field-width-xs-min: 45%;
$copy-job-field-width-sm-min: 100%;
$copy-job-field-width-md-min: $copy-job-field-width-xs-min;


// == Admin Account Preferences page

// ##
$table-border: #ddd !default; // table and cell border


// == Sample Candidate Card

// ##
$sample-candidate-sub-head-color: $dove-gray;
$sample-candidate-tooltip-bg: $white;
$sample-candidate-tooltip-border: $midnight-blue;
$sample-candidate-tooltip-color: $midnight-blue;

// == Fee Split color

// ##
$fee-split: $crusta;

// == Placement Fee Success

// ##
$placement-fee-success-border: $casper;


// == Checkbox Tree

// ##
$checkbox-tree-item-hover-bg: $onahau;
$check-breathing-room: spacer(1);
$check-indentation: calc(spacer(4) + $check-breathing-room);


// == Select Partners

// ##
$select-recruiters-sm-col-width: spacer(30);


// == Legend
$legend-color: $scout-primary;
$legend-border-color: transparent;
