// coding:utf-8
// Copyright 2023 Scout Exchange, LLC. All Rights

.partselect-summary {
  display: flex;
  flex-direction: column;
  margin-bottom: spacer(2.5);

  .count-box {
    margin-top: spacer(2.5);
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    .count-box {
      margin-left: auto;
      margin-top: 0;
    }
  }
}

.partselect-details {
  background-color: $white;
  display: grid;
  grid-template-columns: 50% 50%;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.partselect-details-block {
  border-right: 1px solid $base-border-color;
  margin: spacer(2.5) 0 spacer(2.5) spacer(2.5);
  padding-right: spacer(2.5);

  &:last-child {
    border: 0;
  }

  @include media-breakpoint-up(md) {
    // the layout up to screen-md-min is 2 rows of 2 columnes
    // this code removes the border from the 2nd column in the 1st row
    &:nth-child(2) {
      border: 0;
    }
  }
}

.partselect-job-title {
  font-size: 16px;
  font-weight: $font-weight-bold;
}

.partselect-draft-job {
  margin-left: auto;
  font-size: 32px;
  color: $brand-warning;
}

.partselect-count-title,
.partselect-count-number {
  display: block;
  text-align: center;
}

.partselect-status-item {
  padding: 0 0.5rem;
}

.parselect-recommendation-container {
  margin-top: spacer(5);
  display: flex;
  align-items: center;
  gap: spacer(4);
}

.partselect-standard-header {
  background: $body-background;
  position: relative;
  z-index: 1;
}

.partselect-expanded-header {
  background: $primary-variant-lighter;
  position: sticky;
  top: spacer(12.5);

  .partselect-recruiter-select {
    display: flex;
    align-items: center;
  }
}

.partselect-expanded-details {
  background: $primary-variant-lighter;
  margin-bottom: spacer(4);
  padding: 0 spacer(5) spacer(3) spacer(5);

  .table {
    background: transparent;

    th {
      border-bottom: 1px dotted $primary-variant-darker;
    }
  }
}

.partselect-recruiter-selected-name {
  font-weight: $font-weight-semibold;
  font-size: 21px;
}

.partselect-recruiter-history-head {
  font-size: $font-size-lg;
  font-weight: $font-weight-semibold;
  margin-bottom: 5px;
  padding-left: 5px;
}

.partselect-recruiter-list {
  isolation: isolate;
  margin-bottom: 0;

  .checkbox {
    margin: 0;
  }

  .partselect-select-all {
    font-weight: $font-weight-bold;
  }
}

.partselect-head {
  background: $body-background;
  position: sticky;
  top: 0;
  z-index: 2;
}

.partselect-recruiter-name {
  display: flex;

  .svg {
    margin-left: spacer(1);
  }
}

.partselect-all-history-loaded {
  color: $brand-success;
}
