// coding:utf-8
// Copyright 2013-2024 Scout Exchange, LLC. All Rights

// Utilities // --------------------------------------------------
// copied from Bootstrap 4 alpha
// classes for setting margins to 0 in various directions
.m-a-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

// classes for setting padding to 0 in various directions
.p-a-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

// classes for setting 2px margin
.m-l-xxs {
  margin-left: 2px !important;
}

// classes for setting 5px margin
.m-a-xs {
  margin: 5px !important;
}

.m-t-xs {
  margin-top: 5px !important;
}

.m-r-xs {
  margin-right: 5px !important;
}

.m-b-xs {
  margin-bottom: 5px !important;
}

.m-l-xs {
  margin-left: 5px !important;
}

.m-x-xs {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.m-y-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

// classes for setting negative 5px margins
.m-t-n-xs {
  margin-top: -5px !important;
}

.m-l-n-xs {
  margin-left: -5px !important;
}

// classes for setting 5px padding
.p-a-xs {
  padding: 5px !important;
}

.p-t-xs {
  padding-top: 5px !important;
}

.p-r-xs {
  padding-right: 5px !important;
}

.p-b-xs {
  padding-bottom: 5px !important;
}

.p-l-xs {
  padding-left: 5px !important;
}

.p-x-xs {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.p-y-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

// classes for setting 10px margin
.m-r-sm {
  margin-right: 10px !important;
}

.m-b-sm {
  margin-bottom: 10px !important;
}

.m-l-sm {
  margin-left: 10px !important;
}

.m-t-sm {
  margin-top: 10px !important;
}

.m-x-sm {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.m-y-sm {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

// classes for setting 10px padding
.p-t-sm {
  padding-top: 10px !important;
}

.p-y-sm {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

// classes for setting 15px margin
.m-t-md {
  margin-top: 15px !important;
}

.m-r-md {
  margin-right: 15px !important;
}

.m-b-md {
  margin-bottom: 15px !important;
}

.m-l-md {
  margin-left: 15px !important;
}

.m-x-md {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.m-y-md {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

// classes for setting 15px padding
.p-l-md {
  padding-left: 15px !important;
}

.p-r-md {
  padding-right: 15px !important;
}

// classes for setting 20px margin
.m-t-lg {
  margin-top: 20px !important;
}

.m-r-lg {
  margin-right: 20px !important;
}

.m-b-lg {
  margin-bottom: 20px !important;
}

.m-l-lg {
  margin-left: 20px !important;
}

.m-x-lg {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.m-y-lg {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

// classes for setting 20px padding
.p-t-lg {
  padding-top: 20px !important;
}

// classes for setting 30px margin
.m-t-xl {
  margin-top: 30px !important;
}

.m-b-xl {
  margin-bottom: 30px !important;
}

.m-y-xl {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

// classes for setting 30px padding
.p-r-xl {
  padding-right: 30px !important;
}

// classes for vertical alignment
.align-middle {
  vertical-align: middle !important;
}

// Layout
// utility classes to quickly help with layout
.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.cursor-pointer {
  cursor: pointer;
}

.underline,
%underline {
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

.text-normal {
  font-weight: 400;
}

.deemphasize {
  opacity: 0.6;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.xxsmall {
  font-size: 10px;
}

.relative {
  position: relative;
}

.width-auto {
  width: auto !important;
}

.text-inactive {
  color: $inactive-text !important;
}

.nowrap {
  white-space: nowrap;
}


// utility class to denote that text will wrap on line breaks
.ensure-wrap {
  overflow-wrap: break-word;
}

.force-wrap {
  @include force-text-wrap;
}

// apply "margin-bottom: 10px" on screens
// that are "small" and larger
.sm-min-m-b-sm {
  @include media-breakpoint-up(sm) {
    &.form-group {
      margin-bottom: 10px;
    }
  }
}

.transition-color {
  transition: color 0.6s;
}
