// coding:utf-8
// Copyright 2021 Scout Exchange, LLC. All Rights

.sample-candidate-wrap {
  margin-top: 40px;

  // re-define stroke color to use the new blue
  .svg-sample-tooltip-arrow-outer {
    stroke: $sample-candidate-tooltip-border;
  }
}

.sample-candidate-sub-head {
  color: $sample-candidate-sub-head-color;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  margin-top: spacer(8);
}

// make the candidate name look like a link
.sample-candidate-name {
  color: $scout-action;
  text-decoration: underline;
}

.sample-candidate-tooltip {
  color: $sample-candidate-tooltip-color;
  border: 1px solid $sample-candidate-tooltip-border;
  background-color: $sample-candidate-tooltip-bg;
  font-size: $font-size-base;
  padding: 10px 15px;
  position: absolute;
  max-width: 500px;
  z-index: 2;
}

.sample-candidate-tooltip-name {
  left: 100%;
  width: 280px;
  top: -20px;
  margin-left: 13px;

  .svg-sample-tooltip-arrow {
    left: -11px;
    top: 50%;
    margin-top: -10px;
  }
}

.sample-candidate-tooltip-recruiter {
  width: 223px;
  margin-top: 13px;
  left: 85px;

  .svg-sample-tooltip-arrow {
    transform: rotate(90deg);
    top: -11px;
    left: 50%;
    margin-left: -10px;
  }
}

.sample-candidate-tooltip-status {
  width: 234px;
  margin: 13px;
  top: -36px;
  left: 166px;

  .svg-sample-tooltip-arrow {
    top: 50%;
    right: auto;
    bottom: auto;
    left: -11px;
    margin-top: -10px;
  }

  @include media-breakpoint-up(sm) {
    right: 98%;
    left: auto;

    .svg-sample-tooltip-arrow {
      transform: rotate(180deg);
      right: -11px;
      bottom: auto;
      left: auto;
      margin-top: -10px;
    }
  }

  @include media-breakpoint-up(md) {
    top: 92%;
    right: -36px;
    bottom: auto;
    left: auto;

    .svg-sample-tooltip-arrow {
      transform: rotate(90deg);
      top: -1px;
      right: auto;
      bottom: auto;
      left: 50%;
      margin-left: -10px;
    }
  }

  @include media-breakpoint-up(lg) {
    bottom: 100%;
    right: -21px;
    top: auto;

    .svg-sample-tooltip-arrow {
      transform: rotate(-90deg);
      top: auto;
      right: auto;
      bottom: -11px;
    }
  }
}

// de-emphasize content that is not called out by a tooltip
.sample-candidate-dim {
  opacity: 0.5;
}
