// coding:utf-8
// Copyright 2013-2022 Scout Exchange, LLC. All Rights

// Checkbox styles

// for new IPS work

.checkbox-item {
  display: flex;

  // remove default bottom margin on <label>
  margin: 0;
  padding-top: spacer(0.5);
  padding-bottom: spacer(0.5);

  // when background-color appears on hover, the padding-left adds some
  // spacing between the checkbox and edge of the background color
  padding-left: spacer(1);

  .checkbox {
    margin-right: spacer(1.5);
  }
}

.checkbox-item-already-added {
  font-size: 13px;
  margin-top: spacer(0.5);
  margin-right: spacer(1.5);
  text-align: center;
  width: spacer(3.25);
}

.checkbox-item-content {
  // make it a flex display
  display: flex;

  // make it so this section fills all of the horizontal space available to it
  flex: 1;
}

.checkbox-item-wide-label {
  // "wide" refers to how it should fill the space until the next element
  flex: 1;
}

.checkbox-item-icon {
  margin-left: spacer(1);

  &,
  .svg-diversity,
  .svg-preferred {
    width: spacer(5);
  }

  .svg {
    position: absolute;
  }
}

.checkbox-item-rec,
.checkbox-item-rating {
  width: $select-recruiters-sm-col-width;
}

.checkbox-item-rec {
  // match layout styles of .select-recruiters-rec-col
  padding-right: spacer(2);
  text-align: center;
}

.checkbox-item-rating {
  text-align: center;
  padding-right: spacer(1);
  padding-left: spacer(1);
}

.checktree-parent-group {
  > .checkbox-item {
    font-weight: $font-weight-semibold;
    margin-left: $check-indentation;
  }
}

.checktree-child-group {
  // margin needed so background-color on hover stops at parent
  margin-left: $check-indentation;

  .checkbox-item {
    padding-left: spacer(1) + $check-indentation;
  }
}

// stylelint-disable no-descending-specificity

// background hover color
// in a checktree-group
.checktree-group {
  // ...for any checkbox-item
  .checkbox-item {
    // ...when you hover on it
    &:hover {
      // ... add a background color to any checktree-group that is a sibling
      ~ .checktree-group {
        background-color: $checkbox-tree-item-hover-bg;
      }
    }
  }
}

// For now, scope the hover to the select-recruiters "checktree" component so
// that it doesn't affect the Partner listing in the Job Listing page
.checktree {
  .checkbox-item {
    cursor: pointer;

    // undo margin-right styling on non-IPS page checkbox-item that was
    // indenting the right side of the element
    font-weight: $font-weight-normal;
    margin-right: 0;
    padding-top: spacer(0.75);
    padding-bottom: spacer(0.75);

    &:hover {
      background-color: $checkbox-tree-item-hover-bg;
    }
  }

  .svg-diversity,
  .svg-preferred {
    // "height" is needed to display correctly in IE11
    height: spacer(5);
    width: spacer(5);
  }
}

// stylelint-enable no-descending-specificity
